.trigger-popup-aide{
  cursor: pointer;
}

.servicesContainer-section-head-description{
  .trigger-popup-aide{
    background-color: $primary-color;
    padding: 5px;
    color: white;
  }
}

.servicesContainer-section-headContainer{
  margin: 30px auto;
  max-width: 980px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @include media("<desktop"){
    flex-direction: column;
  }
  &-title{
    color: $grey;
    font-weight: bold;
    max-width: 500px;
    h1, h2, h3{
      font-size: 40px;
      font-weight: bold;
      @include media('<tablet'){
        font-size: 30px;
      }
      span{
        color: $primary-color;
      }
    }
  }
  &-image{
    @include media("<desktop"){
      max-width: 100%;
      img{
        max-width: 100%;
      }
    }
  }
}


//desktop styles
@include media('>=desktop'){

  .plusDinfos {
    width: 100%;
    z-index: 2;
    position: fixed;
    bottom: 0;
  }

  .servicesContainer {
    margin: auto;
    width: 980px;
  }

  .servicesContainer-section-head-item{
    display: inline-block;
    width: 50%;
    vertical-align: top;
    padding: 10px;
  }

  .servicesContainer-head-titre {
    font-family: Arial, sans-serif;
    font-weight: 100;
    color: #818181;
    font-size: 72px;
    line-height: 1;
    margin: 0;
  }

  .servicesContainer-head-img {

  }


  .servicesContainer-section-head-description{
    margin-top: 20px;
  }

  .servicesContainer-section-head-description strong{
    color: black;
  }

  .servicesContainer-section-head-description-title{
    margin: 10px 0px 0px;
  }
  .servicesContainer-section-head-description-content{
    color: #8B8B8B;
    font-size: 14px;
  }

  .servicesContainer-section-head-description-content a{
    text-decoration: underline;
  }

  .servicesContainer-verreContainer {
    padding: 20px;
    display: inline-block;
    float: left;
  }

  .servicesContainer-article-titre {
    font-size: 16px;
    margin: 0;
    margin-top: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    border-left: 1px #8b8b8b solid;
  }

  .servicesContainer-article-text {
    margin: 0;
    padding-bottom: 10px;
    padding-left: 20px;
    border-left: 1px #8b8b8b solid;
    color: #8B8B8B;
    font-size: 16px;
    font-weight: bold;
  }

  .servicesContainer-article-text-small {
    margin-top: 0;
    font-size: 14px;
    color: #818181;
    margin-left: 20px;
  }

  .servicesContainer-article-liste {
    margin-top: 0;
    font-size: 14px;
    color: #818181;
  }

  .servicesContainer-article-top {
    display: inline-block;
    width: 780px;
    vertical-align: top;
    margin-bottom: 10px;
  }

  .servicesContainer-article-colGauche {
    clear: both;
    width: 490px;
    display: inline-block;
  }

  .servicesContainer-article-colDroite {
    width: 490px;
    display: inline-block;
  }
  .servicesContainer-illustrContainer {
    display: yes;
  }
}

@include media('>=tablet','<desktop'){
  /*=================================================
================SECTION SERVICES===================
================================================*/


  .servicesContainer {
    padding: 0 3% 20px;
  }

  .servicesContainer-head-titre {
    font-size: 42px;
    font-weight: 100;
    color: #949494;
    margin: 0;
  }

  .servicesContainer-section-head-description-content {
    color: #949494;
  }

  .servicesContainer-section-head-description-content strong {
    color: #000000;
  }

  .servicesContainer-section-head-item {
    width: 50%;
    display: inline-block;
    margin: 0;
  }

  .servicesContainer-section-head-item:nth-child(1) {
    vertical-align: top;
  }

  .servicesContainer-section-head-item:nth-child(2) {
    max-width: 462px;
  }

  .servicesContainer-head-img {
    width: 100%;
  }

  .servicesContainer-verreContainer {
    display: block;
    float: left;
  }

  .servicesContainer-article-top {
    display: inline-block;
    width: 70%;
    vertical-align: top;
  }

  .servicesContainer-article-colGauche {
    clear: both;
  }

  .servicesContainer-article-top,
  .servicesContainer-article-colGauche,
  .servicesContainer-article-colDroite,
  .servicesContainer-article {
    position: relative;
    margin-bottom: 30px;
    color: #949494;
  }


  .servicesContainer-article-titre {
    color: #000000;
    font-size: 16px;
    margin: 0;
    margin-top: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    border-left: 1px #8b8b8b solid;
  }


  .servicesContainer-article-text {
    margin: 0;
    padding-bottom: 10px;
    padding-left: 20px;
    border-left: 1px #8b8b8b solid;
    color: #8B8B8B;
    font-size: 16px;
  }
  .servicesContainer-illustrContainer {
    display: block;
  }

  .servicesContainer-section-headContainer,
  .servicesContainer-section-headContainer .servicesContainer-section-head-item {
    width: 100%;
  }

  .servicesContainer-section-headContainer .servicesContainer-section-head-item img {
    max-width: 100%;
    height: auto;
  }

  .servicesContainer-section-headContainer .servicesContainer-section-head-item h2 {
    padding: 0;
  }

  .servicesContainer-section-headContainer .servicesContainer-section-head-item {
    padding: 0;
  }

  .servicesContainer-section-headContainer .servicesContainer-section-head-item .servicesContainer-support-text {
    padding: 10px 0 0;
  }

  .filDactu .guide-idel {
    width: 100%;
    margin: 0;
    left: 0;
  }

  .filDactu .guide-idel img {
    max-width: 100%;
    height: auto;
  }
}


@include media('<tablet'){
  /*=================================================
================SECTION SERVICES===================
================================================*/


  .servicesContainer {
    padding: 0 3% 20px;
  }

  .servicesContainer-head-titre {
    font-size: 32px;
    font-weight: 100;
    color: #949494;
    margin: 0;
  }

  .servicesContainer-section-head-description-content {
    color: #949494;
  }

  .servicesContainer-section-head-description-content strong {
    color: #000000;
  }

  .servicesContainer-section-head-item:nth-child(2) {
    width: 100%;
    max-width: 462px;
    margin: auto;
  }

  .servicesContainer-head-img {
    width: 100%;
  }

  .servicesContainer-verreContainer {
    display: none;
  }

  .servicesContainer-article-top,
  .servicesContainer-article-colGauche,
  .servicesContainer-article-colDroite,
  .servicesContainer-article {
    position: relative;
    margin-bottom: 30px;
    color: #949494;
  }

  .servicesContainer-article-titre {
    font-size: 12px;
    font-weight: 100;
    margin: 10px 0;
  }

  .servicesContainer-article-text {
    padding-left: 20px;
  }
  .servicesContainer-illustrContainer {
    display: none;
  }

  .servicesContainer-section-headContainer,
  .servicesContainer-section-headContainer .servicesContainer-section-head-item {
    width: 100%;
  }

  .servicesContainer-section-headContainer .servicesContainer-section-head-item img {
    max-width: 100%;
    height: auto;
  }

  .servicesContainer-section-headContainer .servicesContainer-section-head-item h2 {
    padding: 0;
  }

  .servicesContainer-section-headContainer .servicesContainer-section-head-item {
    padding: 0;
    margin-bottom: 20px;
  }

  .servicesContainer-section-headContainer .servicesContainer-section-head-item .servicesContainer-support-text {
    padding: 10px 0 0;
  }


  .servicesContainer .servicesContainer-section article {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }

  .servicesContainer .servicesContainer-section article h3 {
    font-size: 25px;
    text-align: center;
    margin: 15px 0;
  }

  .servicesContainer .servicesContainer-section article img {
    width: 100%;
    height: auto;
  }

  .servicesContainer .servicesContainer-section article .servicesContainer-article-text,
  .servicesContainer .servicesContainer-section article .servicesContainer-article-liste {
    width: 100%;
    padding: 0;
  }

  .servicesContainer .servicesContainer-section article .servicesContainer-article-liste li {
    list-style-position: inside;
  }

  .servicesContainer .servicesContainer-section article .servicesContainer-article-text-350,
  .servicesContainer .servicesContainer-section article .servicesContainer-article-liste-350 {
    width: 100%;
  }

  .servicesContainer .servicesContainer-section article img.img-responsive-services {
    width: auto;
    height: 139px;
  }
}
