.plusDinfos {
    width: 100%;
    z-index: 2;
    position: fixed;
    bottom: 0;
}

.presentation-container {
    margin: 30px auto;
    max-width: 980px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include media("<desktop"){
        flex-direction: column;
    }
    @include media('<tablet'){
        padding: 0 20px;
    }
    &-title{
        color: $grey;
        font-weight: bold;
        max-width: 500px;
        h1, h2, h3{
            font-size: 40px;
            font-weight: bold;
            @include media('<tablet'){
                font-size: 30px;
            }
            span{
                color: $primary-color;
            }
        }
    }
    &-image{
        @include media("<desktop"){
            max-width: 100%;
            img{
                max-width: 100%;
            }
        }
    }
}


.presentation-profil-container {

    margin: auto;
    width: 950px;

    .presentation-profil-container-titre {
        color: #949494;
        font-size: 18px;
        font-weight: 200;
        margin: 0;
    }

    .presentation-profil-container-text {

    }

    .groupe-pave {
        margin-bottom: 15px;
        text-align: center;

        .pave {
            color: $primary-color;
            display: inline-block;
            background-color: #e6e6e5;
            width: 228px;
            text-align: center;
            font-weight: 700;
            height: 130px;
            vertical-align: top;
            margin: 3px;

            .label {
                margin-top: -30px;
            }
        }
    }
}

.pricing-wrapper{
    background-color: #374857;
    padding-bottom: 40px;
    padding-top: 40px;

    &.large-pricing-wrapper{

    }

    .pricing-container{
        max-width: 980px;
        margin: auto;

        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;


        .pricing-content{
            background-color:#ffffff;
            max-width: 703px;
            overflow: hidden;

            &-header{
                margin: 20px 20px 0;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;

                &-text{
                    width: 50%;
                    margin-bottom: 30px;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;

                    @include media('<tablet'){
                        width: 100%;
                    }

                    img{
                        width: 100%;
                    }

                    .wrapper-price {
                        border-top: 1px solid #cbd7dc;
                        border-bottom: 1px solid #cbd7dc;
                        padding: 0 20px 10px 20px;

                        .dash {
                            margin: -9px 0 0;
                            display: flex;
                            justify-content: center;

                            span {
                                background: #fff;
                                padding: 0 10px;
                            }
                        }

                        p{
                            color: #929292;
                            font-size: 14px;
                            text-align: center;
                            margin: 0;

                            &.previous-price {
                                margin: 0;

                                sup {
                                    font-size: 15px;
                                }

                                span{
                                    &:first-child{
                                        position: relative;
                                        font-size: 25px;
                                        font-weight: bold;

                                        &:before{
                                            content:'';
                                            position: absolute;
                                            top: 49%;
                                            left: 0;
                                            right: 0;
                                            height: 1px;
                                            background-color: currentColor;
                                        }
                                    }

                                    &.month {
                                        font-size: 15px;
                                        margin: 0 0 0 0;
                                    }
                                }
                            }

                            &.price {
                                font-size: 60px;
                                font-weight: bold;
                                margin: 0;

                                sup {
                                    font-size: 35px;
                                }

                                span{
                                    color: $primary-color;

                                    &.month {
                                        color: #000000;
                                        font-size: 22px;
                                        margin: 0 0 0 -33px;
                                    }
                                }
                            }
                        }
                    }
                }

                &-cta{
                    text-align: center;
                    margin-bottom: 30px;

                    button{
                        font-family: "bignoodletitlingregular", Arial, sans-serif;
                        font-size: 22px;
                        padding: 5px 15px 0;
                        text-align: center;
                        background-color: $primary-color;
                        color: white;
                        margin: auto;
                        border: none;
                        cursor: pointer;
                    }
                }

                &-title{
                    font-family: "bignoodletitlingregular", Arial, sans-serif;
                    text-align: center;
                    font-size: 25px;
                    color:gray;
                }

                &-picture{
                    width: 50%;

                    @include media('<tablet'){
                        width: 100%;
                    }

                    img{
                        width: 100%;
                    }
                }
            }

            &-teaser{
                font-size: 16px;
                color: #617381;
                margin: 20px 20px 30px;
            }

            &-features{
                margin: 20px 20px 30px;

                &-title{
                    color:gray;
                    font-family: "bignoodletitlingregular", Arial, sans-serif;
                    font-size: 30px;
                    margin-bottom: 10px;
                }

                &-body{
                    font-size: 14px;
                    color: #617381;

                    ul{
                        column-count: 2;
                        padding-left: 0;
                        list-style-position: inside;
                        margin-top: 0;

                        @include media('<tablet'){
                            column-count: 1;
                        }
                    }
                }
            }

            &-platforms{
                margin: 20px 20px 30px;

                display: flex;
                align-items: center;
                justify-content: space-around;
                flex-wrap: wrap;

                &>*{
                    max-height: 60px;
                    margin: 0 15px;
                }

                .divider{
                    width: 1px;
                    height: 50px;
                    background-color: black;

                    @include media('<tablet'){
                        display: none;
                    }
                }
            }


            &-footer{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                padding: 20px;

                background-color:#e8e8e8;
                font-size: 15px;
                color:#000000;

                &-text{
                    width: 60%;
                    margin-bottom: 30px;

                    @include media('<tablet'){
                        width: 100%;
                    }
                }

                &-picture{
                    margin-right: 30px;

                    @include media('<tablet'){
                        margin-right: 0;
                        margin-bottom: 20px;
                    }

                    img{
                        width: 100%;
                    }
                }
            }

            &-video{
                &-container{

                }

                &-title{

                }

                &-frames{
                    margin-top: 20px;
                    padding: 20px;
                    text-align: center;
                    z-index: 1;

                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    @include media('<desktop'){
                        justify-content: center;
                    }
                }

                &-frame{
                    &-container{
                        margin-bottom: 20px;
                        width: 48%;
                        @include media('<desktop'){
                            width: 100%;
                        }
                    }

                    &-title{
                        margin-bottom: 10px;
                    }

                    iframe{
                        width: 100%;
                    }
                }
            }
        }

        .pricing-container-promo-container{
            margin-left: 25px;

            @include media('<desktop'){
                margin-left: 0;
                margin-top: 25px;
            }
            /*@include media('>=desktop'){
             .medium{
               display: none;
             }
            }*/

            /*@include media('<desktop'){
              .large{
                display: none;
              }
            }*/

        }
    }
}
