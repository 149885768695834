@include media('>=desktop'){
  /*.plusDinfos {
    top: 567px;
  }*/



}

h1{
  display: none;
}
@include media('>=desktop'){
}


.offerSection{
  margin: auto;
  max-width: 980px;
  z-index: 1;

  &-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    &:first-child{
      flex-wrap: nowrap;
      align-items: center;
      gap: 20px;

      img:first-child{
        max-width: 413px;

        @include media("<980px"){
          max-width: 310px;
        }
      }

      @include media("<980px"){
        flex-wrap: wrap;
        gap: 20px;

        img{
          max-width: 310px;
        }
      }

      @include media("<tablet"){
        margin-top: 20px;
      }
    }

    &:last-child{
      margin: 100px 0;
      align-items: center;
      @include media("<980px"){
        margin-top: 20px;
      }

      img{
        max-height: 490px;
      }
    }

    &.offer-list{
      gap: 35px;
    }

    &-item{
      all: unset;
      cursor: pointer;
      max-width: 165px;

      &:hover{
        .offer-icon{
          background-color: $primary-color
        }

        h3{
          color: $primary-color;
        }

        p{
          color: $primary-color;
        }
      }

      .offer-icon{
        width: 40px;
        height: 40px;
        border-radius: 10px;
        background-color: #7c53ff;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color .3s ease-out;
      }

      h3{
        font-size: 20px;
        font-weight: 700;
        color: #7c53ff;
        transition: color .3s ease-out;
      }

      p{
        font-size: 14px;
        font-weight: 400;
        color: #6E6E6E;
        transition: color .3s ease-out;
      }

      &Bottom{
        width: min-content;
        color: #6E6E6E;

        @include media("<tablet"){
          width: 100%;
          padding: 0 10px;

          img{
            width: 100%;
          }
        }
      }
    }
  }
}

.dialog[open]{
    display: block;
    position: fixed;
    z-index: 1000;
    max-height: calc(100vh - 100px);
    max-width: calc(100vw - 40px);
    top: calc( (100vh - 100%)/2 );
    bottom: calc( (100vh - 100%)/2 );
    overflow: hidden;
    border: none;
    padding: 0;
    &:before{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: "";
        display: block;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: -1;
    }
    a{
        display: block;
        max-height: inherit;
        max-width: inherit;
    }
    img{
        display: block;
        max-height: inherit;
        max-width: inherit;
        width: auto;
    }
    form{
        position: absolute;
        top: 20px;
        right: 20px;
    }
}

