@include media('>=desktop'){
  .trigger-popup-aide{
    cursor: pointer;
  }

  .plusDinfos {
    width: 100%;
    z-index: 2;
    position: fixed;
    bottom: 0;
  }

  .servicesContainer {
    margin: auto;
    max-width: 980px;
    width: 100%;
  }

  .servicesContainer-section-headContainer{

  }

  .servicesContainer-section-head-item{
    display: inline-block;
    width: 50%;
    vertical-align: top;
    text-align: right;
    padding: 10px;
  }

  .servicesContainer-support {
    text-align: left;
    font-family: Arial, sans-serif;
    font-weight: 600;
    color: #818181;
    font-size: 28px;
    line-height: 1;
    margin: 0;
    padding: 60px 60px 0px 150px;
  }

  .servicesContainer-support-text{
    text-align: left;
    font-family: Arial, sans-serif;
    font-weight: 100;
    color: #818181;
    font-size: 14px;
    line-height: 1;
    margin: 0;
    padding: 0px 60px 0px 150px;
  }

  .servicesContainer-head-titre {
    font-family: Arial, sans-serif;
    font-weight: 100;
    color: #818181;
    font-size: 72px;
    line-height: 1;
    margin: 0;
  }

  .servicesContainer-head-img {

  }


  .servicesContainer-section-head-description{
    margin-top: 20px;
  }

  .servicesContainer-section-head-description strong{
    color: black;
  }

  .servicesContainer-section-head-description-title{
    margin: 10px 0px 0px;
  }
  .servicesContainer-section-head-description-content{
    color: #8B8B8B;
    font-size: 14px;
  }

  .servicesContainer-section-head-description-content a{
    text-decoration: underline;
  }

  .servicesContainer-verreContainer {
    padding: 20px;
    display: inline-block;
    float: left;
  }

  .servicesContainer-article-titre {
    font-size: 35px;
    color:#8b8b8b;
    margin: 0;
    margin-top: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    display: inline-block;

  }

  .servicesContainer-article-text {
    max-width: 450px;
    margin: 0;
    padding-bottom: 10px;
    padding-left: 20px;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    display: inline-block;

  }

  .servicesContainer-article-teleintervention {
    width: 600px;
    margin: 0;
    padding-bottom: 10px;
    padding-left: 20px;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    display: inline-block;

  }

  .servicesBouton {
    text-align: center;
    width: 300px;
    height: 30px;
    margin: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    background-color: $primary-color;

  }

  .servicesContainer-article-text-350 {
    width: 350px;
    margin: 0;
    padding-bottom: 10px;
    padding-left: 20px;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    display: inline-block;

  }

  .servicesContainer-article-text-small {
    margin-top: 0;
    font-size: 14px;
    color: #818181;
    margin-left: 20px;
  }

  .servicesContainer-article-liste {
    width: 450px;
    padding-left: 20px;
    margin-top: 0;
    font-size: 14px;
    color: #818181;
    display: inline-block;
  }

  .servicesContainer-article-liste-350 {
    width: 350px;
    padding-left: 20px;
    margin-top: 0;
    font-size: 14px;
    color: #818181;
    display: inline-block;
  }

  .servicesContainer-article-liste-teleintervention1 {
    vertical-align: text-top;
    width: 180px;
    padding-left: 20px;
    margin-top: 0;
    font-size: 14px;
    color: #818181;
    display: inline-block;
  }

  .servicesContainer-article-top {
    display: inline-block;
    width: 780px;
    vertical-align: top;
    margin-bottom: 10px;
  }

  .servicesContainer-article-gris {
    background-color:#fafafa;
    clear: both;
    display: inline-block;
  }

  .servicesContainer-article-blanc {
    background-color:#ffffff;
    clear: both;
    display: inline-block;
  }

  .ordi
  {
    background-image: url("http://www.infimax.fr/media/images/ordi.jpg");
    float:right;
    width:296px;
    height:323px;
    background-repeat: no-repeat;
    display: inline-block
  }

}


@include media('>=tablet','<desktop'){
}


@include media('<tablet'){
}

.servicesContainer-article-titre{
  max-width: 450px;
}
.servicesContainer-article-liste > ul, .servicesContainer-article-liste-350 > ul, .servicesContainer-article-liste > p, .servicesContainer-article-liste-350 > p{
  margin-top: 0;
}
.servicesContainer-article-liste > ul, .servicesContainer-article-liste-350 > ul{
  padding-left: 20px;
}
.servicesContainer-article-liste > ul{
  max-width: 450px;
}
.servicesContainer-article-liste-350 > ul{
  max-width: 350px;
}
