.pricing-wrapper {
  margin-top: -10px;
}

.pricing-table-offer-table-button {
  color: #ffffff;
  background-color: $primary-color;
  bottom: -50px!important;
}

.pricing-table-offer-table-price-number {
  font-size: 60px;
}

.plusDinfos.offer-focus-ortho{
  position: relative;
  top: -795px;
}

.offer-focus-ortho .plusDinfos-close {
  display: block;
}

.princing-table-rent-row-video{
  top: -680px;
  padding-left: 25px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.pricing-table-included-table{
  margin-top: 10px;
}

.pricing-table-included-table-list-compatibilite{
  margin-bottom: 40px;
  margin-top: 10px;
}

.lecteur-image {
  margin-top: -30px;
  margin-right: 15px;
  margin-bottom: 0px;
}

.princing-table-rent-row-mentions {
  color: #617381;
  font-size: 13px;
  background-color: #ffffff;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  padding-bottom: 30px;
}

/*
.pricing-table-included-table-title-text {
	color: #D1316D;
}

.princing-table-beneficient-table-title-text {
	color: #D1316D;
}
*/
.princing-table-beneficient-table-moovBox-banner {
  background-color: #FFFFFF;
}
/*
.princing-table-beneficient-table-moovBox-title {
    border-color: #D1316D;
}

.pricing-table-included-table-title {
	background-image: url(../images/separator.png);
}

.princing-table-beneficient-table-row:first-child {
	background-image: url(../images/separator.png);
}
*/
.selected {
  background-color: $primary-color;
  color: #ffffff;
}
/*
@media only screen and (min-width: 640px) and (max-width: 979px){
	.princing-table-beneficient-table-moovBox-banner {
		border-left: 8px #D1316D solid;
		border-right: 7px #D1316D solid;
	}
}
*/

/***********************/
/* NEW TABLES 2017-02  */
/***********************/

.toggle.orange-bg ,
.toggle.blanc-bg {
  display: none;
}

.display .toggle.orange-bg,
.display .toggle.blanc-bg{
  display:inline-block;
}

.label-more1,
.label-more2,
.label-more3,
.label-more4,
.label-more5,
.label-more6,
.label-more7,
.label-more8,
.label-more9{
  display: none;
}
.label-more1.displayrow,
.label-more2.displayrow,
.label-more3.displayrow,
.label-more4.displayrow,
.label-more5.displayrow,
.label-more6.displayrow,
.label-more7.displayrow,
.label-more8.displayrow,
.label-more9.displayrow{
  display: table-row;
}

.more-info.less1,
.more-info.less2,
.more-info.less3,
.more-info.less4,
.more-info.less5,
.more-info.less6,
.more-info.less7,
.more-info.less8,
.more-info.less9{
  display: none;
}
.displayless .less1,
.displayless .less2,
.displayless .less3,
.displayless .less4,
.displayless .less5,
.displayless .less6,
.displayless .less7,
.displayless .less8,
.displayless .less9{
  display: block;
}
.displayless .more1,
.displayless .more2,
.displayless .more3,
.displayless .more4,
.displayless .more5,
.displayless .more6,
.displayless .more7,
.displayless .more8,
.displayless .more9{
  display: none;
}

.feature-label a,
.white-title a{
  text-decoration: none;
}

/* 1st TABLE : WHITE BG */
.white-bg th {
  height: 75px;
  font-family: "bignoodletitlingregular", Arial, sans-serif;
  font-weight: normal;
}
.white-bg th.orange-title {
  width: 50%;
}


th.offre-title {
  width: 25%;
  vertical-align: bottom;
}

.offre-title.orange-bg{
  background-image: url(../../images/offres2017/degrade_1.png);
  background-size: cover;
}
.offre-title.orange-bg .orange-title{
  color: #ffffff;
}

.white-bg td.paddingtop {
  padding-top: 30px;
}
.white-bg .big-title {
  font-size: 40px;
  margin:10px;
}
.white-bg .big-title.offre1{
  color:#707173;
}
.white-bg .big-title.offre2{
  color:#ffffff;
}
td.function-label {
  padding: 0;

}
.quoi{
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
}




.toggle.white-bg-2lignes {
  padding: 3px;
  padding-top: 6px;
  min-height: 50px;
  font-size:15px;

}

.function-label img {
  float:right;
}

.white-bg .orange-title {
  font-family: "bignoodletitlingregular", Arial, sans-serif;
  font-size: 22px;
  color: $primary-color;
  font-weight: normal;
  vertical-align: bottom;
  text-align: left;
}
.white-bg .offre-title .orange-title {
  text-align: center;
}

.orange-label{
  color: $primary-color;
  font-size: 15px;
}

.more-info{
  color:#000000;
  font-size:12px;
  padding-top:5px;
}
.tick-droit{
  background-color:#ebefc8;
}
.tick-droit-vide{
  background-color:#FFFFFF;
  border: 1px solid #FFFFFF;
}

/* 2nd TABLE : ORANGE BG */
table.orange-bg {
  width: 90%;
  margin: 32px;
  background-image: url(../../images/offres2017/degrade_2.png);
  background-size: cover;
  color: #FFFFFF;
}
.orange-bg th{
  height: 50px;
}
.orange-bg th.white-title {
  width: 50%;
}
.orange-bg th.empty-title {
  width: 25%;
}
.orange-bg td {
  border: 1px solid #FFFFFF;
}
.orange-bg .white-title {
  font-family: "bignoodletitlingregular", Arial, sans-serif;
}
.orange-bg th.big-title {
  font-size: 32px;
  font-weight: normal;
}
.orange-bg .small-title {
  font-size: 22px;
  padding:30px 5px 5px 5px;
}
.orange-bg .small-title .more-info-blanc {
  font-size: 12px;
  font-family:  arial, sans-serif;

}
.more-info-blanc{
  color:#ffffff;
  font-size:13px;
}

.white-label{
  color: #ffffff;
  font-size: 15px;
}
.toggle.white-bg.deuxlignes{
  min-height:50px;
}

.white-label img{
  float: right;
}

.toggle.blanc-bg{
  background-color: #ffffff;
  color: #000000;
  padding: 3px;
  font-size: 13px;
}
.mobile-header{
  display:none;
}



/* List table grid */
.green {
  color: $primary-color !important;
}

.yellow {
  color: #fac005 !important;
}

.bg-yellow {
  background: #fac005 !important;
}

a.bg-yellow:hover {
  background: #000 !important;
}




@include media('>=desktop'){
  //desktop styles
}


@include media('<desktop'){

  .princing-table-beneficient-table-argument-moovBox{
    display: yes;
    width: 50px;
  }

  .pricing-table-offer-table{
    background-color: #ffffff;
    margin: 5px;
  }

  .princing-table-rent-table {
    background-color: #ffffff;
    margin: 5px;
  }

  .princing-table-rent-text
  {
    font-size: 12px;
  }
  .pricing-container{
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 30px;
  }
  .pack{
    width: 200px;
    margin-top: 4px;
  }

}

@include media('>=tablet','<desktop'){
  //tablet styles
  .pricing-table {
    background-color: #ffffff;
  }
  .pricing-container-promo-container{
    margin-top: 50px;
  }
  .pricing-table-included-table-list-compatibilite{
    margin-bottom: 35px;
  }
  .white-bg .big-title {
    font-size: 35px;
    margin: 10px;
  }

  th.offre-title {
    padding-top: 10px;
  }
  .white-bg th.orange-title,
  .orange-bg th.white-title{
    width: 60%;
  }
  th.offre-title,
  .orange-bg th.empty-title {
    width: 20%;
  }
  .white-bg .offre-title .orange-title {
    padding: 0px 10px;
  }

}


@include media('<tablet'){
  .large-pricing-wrapper{
    display: block;
  }
  .white-bg .big-title,
  .white-bg .offre-title .orange-title {
    display: none;
  }
  .offre-title.orange-bg {
    background-image: none;
    background-color: #ebefc8;
  }
  table.white-bg,
  table.orange-bg,
  table.mobile-header{
    width: 95%;
    margin: 0px 5px;
  }
  .white-bg th.orange-title,
  .orange-bg th.white-title{
    width: 60%;
  }
  th.offre-title,
  .orange-bg th.empty-title {
    width: 20%;
  }
  .toggle-text {
    width: 83%;
  }
  .tableau-offre34{
    background-color:#96af23;
    width:20%;
    text-align: right;
    height:47px;
  }
  .tableau-offre34 img{
    margin-right:10px;
  }

  .mobile-header{
    width: 95%;
  }
  .tableau-offre27{
    width:74%;
  }
  td.mobile-offre-27 img {
    margin-left: 20px;
  }
  td.mobile-offre-27 {
    border-top: 1px solid #96af23;
  }

  .white-bg th {
    height: 35px;
  }
  .offre-title,
  .orange-title{
    border-top: 1px solid #96af23;
  }
  .pricing-table {
    background-color: #ffffff;
  }
}
