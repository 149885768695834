.simpleContainer{
  margin: 50px auto;

  @include media('>=desktop'){
    width: 980px;
  }

  @include media('<desktop'){
    width: 90%;
  }

  .presentation-container{
    margin: 30px auto;
    max-width: 980px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include media("<desktop"){
      flex-direction: column;
    }
    &-title{
      color: $grey;
      font-weight: bold;
      max-width: 500px;
      h1, h2, h3{
        font-size: 40px;
        font-weight: bold;
        @include media('<tablet'){
          font-size: 30px;
        }
        span{
          color: $primary-color;
        }
      }
    }
    &-image{
      @include media("<desktop"){
        max-width: 100%;
        img{
          max-width: 100%;
        }
      }
    }
  }

}

