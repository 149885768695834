/*
// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

$gray: #999;
$white: #fff;
$black: #000;
$black-100: #23282d;
$gray-50: #dedede;
$gray-100: #969ba0;
$gray-200: #ebf0f5;
$gray-300: #838d8f;
$gray-400: #e1e6eb;
$gray-500: #9ba5b4;
$blue: #5E9FCA;
$blue-100: #f0f5fa;
$blue-200: rgba(209, 220, 230, 0.8);
$blue-300: #CED6E4;
$blue-400: #1e2841;
$yellow: #fbbe00;
$yellow-100: #fde000;
$purple: #3c465f;

*/
$primary-color: #F28D01;

$grey: #666666;
/*

$base-font-sans-serif: 'Open Sans', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$base-font-serif: 'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;
$font-icomoon: 'icomoon';

// This is the default html and body font-size for the base rem value.
$rem-base: 16px;

// Body
$base-text-color: $white;
$base-background-color: $black;
$font-size-base: 16px !default;
$line-height-base: 1.4375;
$base-font-family: $base-font-sans-serif !default;

$vertical-rhythm: ($line-height-base * 1em);

$base-min-width: 320px;

// Liks
$base-link-color: $black;

// Buttons 
$btn-color: $white !default;
$btn-background: $black !default;
$btn-padding: 0.4em 0.7em !default;
$btn-font-size: inherit !default;

// Forms
$form-element-padding: $btn-padding !default;
$form-element-border-color: $gray !default;
$form-element-focus-border-color: $black !default;
$placeholder-color: $gray !default;

// Headers
$h1-font-size: 30px !default;
$h2-font-size: 17px;
$h3-font-size: 16px;
$h4-font-size: 15px;
$h5-font-size: 14px;
$h6-font-size: 13px;

$headings-color: inherit !default;

$animation-speed: 0.3s;


!** SIZE **! */
//overriding include_media default breakpoints :
$breakpoints: (
  'tablet': 640px,
  'desktop': 980px
);
