@font-face {
  font-family: 'bignoodletitlingoblique';
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/bignoodletitling/bignoodletitling-oblique-webfont.eot');
  src: url('../../fonts/bignoodletitling/bignoodletitling-oblique-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/bignoodletitling/bignoodletitling-oblique-webfont.woff') format('woff'),
  url('../../fonts/bignoodletitling/bignoodletitling-oblique-webfont.ttf') format('truetype'),
  url('../../fonts/bignoodletitling/bignoodletitling-oblique-webfont.svg#bignoodletitlingoblique') format('svg');
}




@font-face {
  font-family: 'bignoodletitlingregular';
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/bignoodletitling/bignoodletitling-webfont.eot');
  src: url('../../fonts/bignoodletitling/bignoodletitling-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/bignoodletitling/bignoodletitling-webfont.woff') format('woff'),
  url('../../fonts/bignoodletitling/bignoodletitling-webfont.ttf') format('truetype'),
  url('../../fonts/bignoodletitling/bignoodletitling-webfont.svg#bignoodletitlingregular') format('svg');
}
