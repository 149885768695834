.presentation-container {
  margin: 30px auto;
  max-width: 980px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @include media("<desktop"){
    flex-direction: column;
  }
  &-title{
    color: $grey;
    font-weight: bold;
    max-width: 500px;
    h1, h2, h3{
      font-size: 40px;
      font-weight: bold;
      @include media('<tablet'){
        font-size: 30px;
      }
      span{
        color: $primary-color;
      }
    }
  }
  &-image{
    @include media("<desktop"){
      max-width: 100%;
      img{
        max-width: 100%;
      }
    }
  }
}

.newsletter-signup-container{

  #mc_embed_signup {
    max-width: 500px;
    text-align: center;
    margin: auto;
  }

  .mc-field-group{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 5px 0;

    label{
      min-width: 130px;
      text-align: left;
    }
  }

  #mc-embedded-subscribe{
    margin-top: 15px;
  }

}

