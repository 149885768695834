.presentation-container {
  margin: 30px auto;
  max-width: 980px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @include media("<desktop"){
    flex-direction: column;
  }
  &-title{
    color: $grey;
    font-weight: bold;
    max-width: 500px;
    h1, h2, h3{
      font-size: 40px;
      font-weight: bold;
      @include media('<tablet'){
        font-size: 30px;
      }
      span{
        color: $primary-color;
      }
    }
  }
  &-image{
    @include media("<desktop"){
      max-width: 100%;
      img{
        max-width: 100%;
      }
    }
  }
}

@include media('>=desktop'){
  .plusDinfos {
    width: 100%;
    z-index: 2;
    position: fixed;
    bottom: 0;
  }

  /*
  .presentation-container-article-text {
      color: #7A7A7A;
      font-family: Arial, sans-serif;
  }

  .presentation-container-article-titre {
      font-family: "bignoodletitlingregular", Arial, sans-serif;
      font-weight: 100;
      margin: 0;
  }

  .presentation-container-article-text2 {
      color: #7A7A7A;
      font-family: Arial, sans-serif;
      width: 500px;
  }
  */

  .fonctionnalites-container {
    background-color: #253543;
  }

  .fonctionnalites-container-section {
    margin: auto;
    width: 980px;
  }

  .fonctionnalites-container-col-gauche,
  .fonctionnalites-container-col-droite {
    display: inline-block;
    margin: 0;
    width: 480px;
  }

  .fonctionnalites-container-col-droite {
    margin-top: 100px;
    vertical-align: top;
  }

  .fonctionnalites-container-col-gauche >
  .fonctionnalites-container-section-article {
    min-height: 140px;
  }

  .fonctionnalites-container-col-droite >
  .fonctionnalites-container-section-article {
    min-height: 140px;
  }

  .fonctionnalites-container-section-titre {
    color: #fff;
    font-family: Arial, sans-serif;
    font-size: 49px;
    font-weight: 100;
    margin-top: 0px;
  }

  .fonctionnalites-container-section-article-container {
    display: inline-block;
    width: 389px;
  }

  .fonctionnalites-container-section-article-container a{
    text-decoration: none;
  }

  .fonctionnalites-container-section-article img {
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
  }

  .fonctionnalites-container-section-article-titre {
    color: #fff;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 24px;
    font-weight: 100;
    margin: 0;
  }

  .fonctionnalites-container-section-article-text {
    color: #98A9B9;
    font-family: Arial, sans-serif;
    font-size: 14px;
    margin: 0;
  }

  .fonctionnalites-container-section-article-span {
    color: #CAD4DE;
    font-family: Arial, sans-serif;
    font-weight: bold;
  }

  .fonctionnalites-container-section-article-readmore{
    outline: 0;
    font-size: 14px;
    font-style: italic;
    text-decoration: none;
  }





  .cadeaux-background {
    background-color: #253543;
  }

  .cadeaux-container {
    margin: auto;
    width: 980px;
  }

  .cadeaux-container-image {
    vertical-align: bottom;
    float: left;
  }

  .cadeaux-container-article {
    display: inline-block;
    width: 100%;
  }

  .cadeaux-container-article-titre {
    color: #fff;
    font-size: 70px;
    font-weight: 100;
    margin: 0;
  }

  .cadeaux-container-article-liste-element {
    color: #fff;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 24px;
    list-style: none;
    margin-bottom: 5px;
    margin-top: 25px;
  }

  .cadeaux-container-article-liste-element-liste {
    padding: 0;
  }

  .cadeaux-container-article-liste-element-liste-element {
    font-family: Arial, sans-serif;
    font-size: 14px;
    list-style: none;
  }

  .cadeaux-container-article-lien-boutton {
    background: #738392; /* Old browsers */
    background: -moz-linear-gradient(top,  #738392 0%, #3a5163 80%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#738392), color-stop(80%,#3a5163)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #738392 0%,#3a5163 80%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #738392 0%,#3a5163 80%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #738392 0%,#3a5163 80%); /* IE10+ */
    background: linear-gradient(to bottom,  #738392 0%,#3a5163 80%); /* W3C */
    border: 2px #3A5163 solid;
    color: #fff;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#738392', endColorstr='#3a5163',GradientType=0 ); /* IE6-9 */
    font-size: 32px;
    margin-bottom: 20px;
    padding: 10px;
    text-align: center;
    display: inline-block;
  }

  .cadeaux-container-article-lien {
    text-decoration: none;
  }


  .offres-logiciel-container {
    padding: 20px;
    background-color: #253543;
  }

  .offres-logiciel-container-img {
    background-repeat: no-repeat;
    margin: auto;
  }

  .videosDemos {
    margin: auto;
    text-align: center;
    background-color: #e8e8e8;
  }

  .videosDemos-titre {
    color: #949494;
    font-family: Arial, sans-serif;
    font-size: 60px;
    font-weight: 100;
    margin: 0;
  }

  .videosDemos-text {
    color: #949494;
    font-family: Arial, sans-serif;
    font-size: 15px;
    margin: 0;
    text-transform: uppercase;
  }

  .videosDemos-image {
    width: 100%;
  }

  .videosDemos-frameContainer{
    margin-top: 30px;
    text-align: center;
  }

  .pave{
    display: inline-block;
    color:#7eb61d;
  }
}

@include media('>=tablet','<desktop'){
  /*=================================================
===============SECTION GRAND TITRE=================
================================================*/

  .presentation-container {
    padding: 10px 3%;
    color: #949494;
    font-size: 18px;
  }

  .presentation-container-head-title {
    font-weight: 100;
    margin: 0;
    font-size: 42px;
  }



  .pave{
    color:#7db51c;
    display:inline-block;
    background-color: #e6e6e5;
    width:230px;
    text-align: center;
    font-weight: 700;
    height:100px;
    vertical-align: top;
    padding:12px;
    margin:10px;
  }



  h1 {
    color: #949494;
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: 200;
    margin: 0;
  }



















  /*=================================================
  ==============SECTION FONCTIONALITES===============
  ================================================*/

  .fonctionnalites-container {
    width: 100%;
    padding: 10px 3%;
    background-color: #253543;
  }

  .fonctionnalites-container-col-gauche,
  .fonctionnalites-container-col-droite {
    display: inline-block;
    width: 50%;
    vertical-align: top;
  }

  .fonctionnalites-container-section-titre {
    font-size: 42px;
    font-weight: 100;
    color: #ffffff;
    margin: 8px 0;
  }

  .fonctionnalites-container-section-article {
    clear: both;
    position: relative;
  }

  .fonctionnalites-active {
    clear: both;
    position: relative;
  }

  .fonctionnalites-container-section-article::after {
    content: "Plus d'infos";
    position: absolute;
    bottom: -20px;
    left: 72px;
    color: #ffffff;
    text-decoration: underline;
  }

  .fonctionnalites-active::after {
    content: "Moins d'infos" !important;
  }

  .fonctionnalites-container-section-article img {
    width: 62px;
    float: left;
    margin-bottom: 20px;
  }

  .fonctionnalites-container-section-article-container {
    margin-left: 72px;
    margin-bottom: 40px;
    cursor: pointer;
    overflow: hidden;
  }

  .fonctionnalites-container-section-article-container a{
    text-decoration: none;
  }



  .fonctionnalites-container-section-article-titre {
    font-family: "bignoodletitlingregular";
    font-size: 20px;
    margin: 0;
    color: #ffffff;
    font-weight: 100;
  }

  .fonctionnalites-container-section-article-text {
    margin: 0;
    margin-bottom: 10px;
    color: #98A9B9;
    height: 0;
    overflow: hidden;
    vertical-align: top;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
  }

  .fonctionnalites-active .fonctionnalites-container-section-article-text {
    height: auto !important;
  }

  .fonctionnalites-container-section-article-span {
    font-weight: bold;
    color: #CAD4DE;
  }

  .fonctionnalites-container-section-article-readmore{
    display: none;
    outline: 0;
    font-size: 14px;
    font-style: italic;
    text-decoration: none;
    margin-bottom: 10px;
  }

  .fonctionnalites-active .fonctionnalites-container-section-article-readmore{
    display: block;
  }











  /*=================================================
  ==================SECTION CADEAUX==================
  ================================================*/

  .cadeaux-background {
    background-color: #253543;
    width: 100%;
    padding: 20px 3% 0;
  }

  .cadeaux-container-image {
    display: inline-block;
    width: 49%;
    vertical-align: bottom;
  }

  .cadeaux-container-article{
    font-size: 0;
  }

  .cadeaux-container-article-titre {
    font-size: 42px;
    color: #ffffff;
    font-weight: 100;
    text-align: center;
  }

  .cadeaux-container-article-liste {
    list-style: none;
    font-family: "bignoodletitlingregular";
    font-size: 24px;
    color: #ffffff;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    margin-top: 0px;
  }

  .cadeaux-container-article-liste-element-liste {
    list-style: none;
    padding: 0 0 5px 20px;
    font-family: Arial, sans-serif;
    font-size: 16px;
  }

  .cadeaux-container-article-lien {
    display: block;
    text-decoration: none;
    width: 50%;
    float: right;
    vertical-align: top;
    position: relative;
    bottom: 75px;
  }

  .cadeaux-container-article-lien-boutton {
    background: #738392;
    background: -moz-linear-gradient(top, #738392 0%, #3a5163 80%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#738392), color-stop(80%,#3a5163));
    background: -webkit-linear-gradient(top, #738392 0%,#3a5163 80%);
    background: -o-linear-gradient(top, #738392 0%,#3a5163 80%);
    background: -ms-linear-gradient(top, #738392 0%,#3a5163 80%);
    background: linear-gradient(to bottom, #738392 0%,#3a5163 80%);
    border: 2px #3A5163 solid;
    color: #fff;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#738392', endColorstr='#3a5163',GradientType=0 );
    font-size: 24px;
    padding: 10px;
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }


  .offres-logiciel-container {
    padding: 20px;
    background-color: #253543;
  }

  .offres-logiciel-container-img {
    background-repeat: no-repeat;
    margin: auto;
  }
}


@include media('<tablet'){
  /*=================================================
===============SECTION GRAND TITRE=================
================================================*/

  .presentation-container {
    padding: 10px 3%;
    color: #949494;
    font-size: 18px;
  }

  .presentation-container-head-title {
    font-weight: 100;
    margin: 0;
    font-size: 32px;
  }

  .videosDemos {
    width: 100%;
    padding: 10px 3%;
  }

  .videosDemos-titre {
    font-size: 42px;
    font-weight: 100;
    color: #949494;
    margin: 10px 0;
  }

  .videosDemos-text {
    color: #949494;
  }

  .videosDemos-frameContainer {
    width: 100%;
    text-align: center;
  }
  .videosDemos-frameContainer iframe {
    width: 100% !important;
    height: 490px !important;
  }



  .pave{
    color:#7db51c;
    display:inline-block;
    background-color: #e6e6e5;
    width:230px;
    text-align: center;
    font-weight: 700;
    height:100px;
    vertical-align: top;
    padding:12px;
    margin:10px;
  }



  h1 {
    color: #949494;
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: 200;
    margin: 0;
  }


















  /*=================================================
  ==============SECTION FONCTIONALITES===============
  ================================================*/

  .fonctionnalites-container {
    width: 100%;
    padding: 10px 3%;
    background-color: #253543;
  }

  .fonctionnalites-container-section-titre {
    font-size: 32px;
    font-weight: 100;
    color: #ffffff;
    margin: 8px 0 15px 0;
  }

  .fonctionnalites-container-section-article {
    clear: both;
    position: relative;
  }

  .fonctionnalites-container-section-article::after {
    content: "Plus d'infos";
    position: absolute;
    bottom: -20px;
    left: 72px;
    color: #ffffff;
    text-decoration: underline;
  }

  .fonctionnalites-active::after {
    content: "Moins d'infos" !important;
  }

  .fonctionnalites-container-section-article img {
    width: 62px;
    float: left;
    margin-bottom: 20px;
  }

  .fonctionnalites-container-section-article-container {
    margin-left: 72px;
    margin-bottom: 40px;
    cursor: pointer;
    overflow: hidden;
  }

  .fonctionnalites-container-section-article-container a{
    text-decoration: none;
  }



  .fonctionnalites-container-section-article-titre {
    font-family: "bignoodletitlingregular";
    font-size: 20px;
    margin: 0;
    color: #ffffff;
    font-weight: 100;
  }

  .fonctionnalites-container-section-article-text {
    margin: 0;
    margin-bottom: 10px;
    color: #98A9B9;
    height: 0;
    overflow: hidden;
    vertical-align: top;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
  }

  .fonctionnalites-active .fonctionnalites-container-section-article-text {
    height: auto !important;
  }

  .fonctionnalites-container-section-article-span {
    font-weight: bold;
    color: #CAD4DE;
  }

  .fonctionnalites-container-section-article-readmore{
    display: none;
    outline: 0;
    font-size: 14px;
    font-style: italic;
    text-decoration: none;
    margin-bottom: 10px;
  }

  .fonctionnalites-active .fonctionnalites-container-section-article-readmore{
    display: block;
  }













  /*=================================================
  ==================SECTION CADEAUX==================
  ================================================*/

  .cadeaux-background {
    background-color: #253543;
    width: 100%;
    padding: 20px 3% 20px;
  }

  .cadeaux-container-image {
    display: none;
  }

  .cadeaux-container-article-titre {
    font-size: 32px;
    color: #ffffff;
    font-weight: 100;
  }

  .cadeaux-container-article-liste {
    list-style: none;
    font-family: "bignoodletitlingregular";
    font-size: 24px;
    color: #ffffff;
  }

  .cadeaux-container-article-liste-element-liste {
    list-style: none;
    padding: 0 0 5px 20px;
    font-family: Arial, sans-serif;
    font-size: 16px;
  }

  .cadeaux-container-article-lien {
    text-decoration: none;
  }

  .cadeaux-container-article-lien-boutton {
    background: #738392;
    background: -moz-linear-gradient(top, #738392 0%, #3a5163 80%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#738392), color-stop(80%,#3a5163));
    background: -webkit-linear-gradient(top, #738392 0%,#3a5163 80%);
    background: -o-linear-gradient(top, #738392 0%,#3a5163 80%);
    background: -ms-linear-gradient(top, #738392 0%,#3a5163 80%);
    background: linear-gradient(to bottom, #738392 0%,#3a5163 80%);
    border: 2px #3A5163 solid;
    color: #fff;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#738392', endColorstr='#3a5163',GradientType=0 );
    font-size: 24px;
    margin-bottom: 20px;
    padding: 10px;
    text-align: center;
  }

  .offres-logiciel-container {
    padding: 20px;
    background-color: #253543;
  }

  .offres-logiciel-container-img {
    background-repeat: no-repeat;
    margin: auto;
  }
}

