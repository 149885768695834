
.headerContainer-header-form-valider {
  background-color: $primary-color;
  border: 0px;
  border-top: 3px $primary-color solid;
}

.headerContainer-header-contact {
  background: $primary-color;
}

.navContainer-liste-element-lien:hover {
  color: $primary-color;
}

.navContainer-contact-item:hover {
  color: $primary-color;
  border-color: $primary-color;
}

.current {
  color: $primary-color;
}

.plusDinfos {
  background-color: $primary-color;
}

.filDactu-idel-980{
  padding-bottom: 15px;
  min-height: 200px;
}

.filDactu-idel-titre.filDactu-actuKine{
  display: inline;
  height: 50px;
}
img.actuKine-logo {
  margin-top: 8px;
  margin-left: 15px;
}

.filDactu-cecilio{
  display: none;
}

.filDactu-newsletter {
  background-image: url('../../images/tablette.png');
}

.filDactu-idel-news-date {
  /*color: $primary-color;*/
}

.filDactu-idel-news-title {
  color: $primary-color;
}

.filDactu-newsletter-form-valider {
  background-color: transparent;
}

.filDactu-cecilio-news-titre {
  color: $primary-color;
}

.connexion {
  background-color: $primary-color;
}

.connexion-titre {
  color: $primary-color;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span{
  background-color: $primary-color;
}

.seoContainer a{
  color: $primary-color;
}

.seoContainer a.flashbilan{
  color: #000000;
  text-decoration: underline;
}

.popupContainer-title{
  background-color: $primary-color;
}

.popupContainer-subtitle{
  color: $primary-color;
}

.popupContainer-form{
  background-color: $primary-color;
}

.profils{
  background-color: #000000;
  color: #ffffff;
  width: 100%;
  /*position: absolute;
  top: 538px;*/
  min-height: 120px;
}
.text-description{
  background-color: #000000;
  color: #ffffff;
  width: 100%;
  position: absolute;
  top: 475px;
  min-height: 50px;
  opacity: 0.75;
}
.text-description.text1,
.text-description.text2,
.text-description.text3{
  display:none;
}
.profils-980 p {
  padding-left: 80px;
  padding-right: 80px;
  font-size: 16px;
}
.profils-980{
  width: 980px;
  margin: auto;
}
#image1,
#image2,
#image3{
  display: inline;
}
#image1-mobile,
#image2-mobile,
#image3-mobile{
  display: none;
}
.filet-blanc{
  border-right: 1px solid #ffffff;
}
.profils-980 a {
  color: #000000;
}
.profil-img{
  width: 285px;
  margin-top: 5px;
  padding-right: 40px;
  margin-left: 30px;
}

/*.carouselContainer {
  margin-bottom: 240px;
}*/

.kineactudesc img,
.kineactudesc iframe {
  display: none;
}

@include media(">=desktop") {

}

@include media(">=tablet", "<desktop") {
  .profils {
    position: fixed;
    top: inherit;
    min-height: 90px;
    bottom: 48px;
    z-index: 5;
  }
  .profils-980 {
    width: 640px;
  }
  .profil-img {
    width: 205px;
    margin-top: 5px;
    padding-right: 15px;
    margin-left: 0;
  }
  .text-description{
    position: fixed;
    top: inherit;
    bottom: 173px;
    z-index: 5;
  }
}


@include media(">tablet") {
  .headerContainer-header-contact {
    background: $primary-color;
  }
}




@include media("<tablet") {

  .profils {
    position: fixed;
    top: inherit;
    bottom: 48px;
    z-index: 5;
  }
  .profils-980 {
    width: 350px;
    text-align: center;
  }
  #image1,
  #image2,
  #image3{
    display: none;
  }
  #image1-mobile,
  #image2-mobile,
  #image3-mobile{
    display: inline;
  }
  .profil-img {
    width: 110px;
    margin-right: 0;
    margin-top: 0;
    padding-right: 0;
    margin-left: 0;
  }
  .text-description{
    position: fixed;
    top: initial;
    bottom: 220px;
    z-index: 5;
  }
  .profils-980 p {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
  }

  .button-wrapper>.button-orange{
    margin: 10px;
  }
  .button-wrapper{
    flex-direction: column;
    align-items: center;
  }

}

.button-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 10px;
  width: inherit;
}
.button-orange{
  display: block;
  text-align: center;
  color: white;
  text-decoration: none;
  font-weight: bold;
  background-color: $primary-color;
  width: max-content;
  padding: 10px 20px;
  font-size: 17px;
  margin-left: -250px;
}

.application-section{
  max-width: 980px;
  margin: 100px auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include media("<tablet"){
    margin: 25px auto;
  }

  .application-description{
    width: 100%;
    padding: 0 10px;

    @include media("<desktop"){
      padding: 0 calc(3% + 10px);
    }

    p{
      color: #8b8b8b;
    }
  }

  .application-container{
    max-width: 300px;
    overflow: visible;
    position: relative;
    width: 300px;
    margin-left: 50px;
    margin-top: 50px;

    &:after{
      display: block;
      content: "";
      bottom: -26px;
      left: -36px;
      position: absolute;
      width: 314px;
      background-image: url("../../images/application/smartphone.png");
      height: 554px;
      background-size: 322px 557px;
      pointer-events: none;
    }

    .tns-ovh{
      overflow: visible;
    }

    .application-slide{
      max-height: 500px;

      img{
        width: 250px;
      }
    }
  }

  .application-slider_controls{
    margin-top: 40px;
    display: flex;
    gap: 100px;

    &--prev, &--next{
      fill: $primary-color;
      cursor: pointer;
    }

    &--next{
      transform: scaleX(-1);
    }
  }

}



