.filDactu-idel-news{
  display: block;
  width: 700px;
}
.filDactu-idel-news-text{
  max-height: unset;
}
.filDactu-idel-980{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.filDactu-idel-titre{
  width: 700px;
}
.button-orange{
  margin-left: 0;
}
@include media('>=tablet','<desktop'){
  .filDactu-idel-news{
    max-width: 600px;
  }
  .filDactu-idel-titre{
    width: inherit;
  }
}


@include media('<tablet'){
  .filDactu-idel-news{
    max-width: 300px;
  }
  .filDactu-idel-titre{
    width: inherit;
  }
}