.column-container{
    display: flex;
    flex-direction: row;
    margin: auto;
    max-width: 980px;
    z-index: 1;
    @include media("<tablet"){
        flex-direction: column;
        align-items: center;
    }
    &-left{
        max-width: 56%;
        padding-right: 20px;
        gap: 50px;
        display: flex;
        flex-direction: column;
        @include media("<tablet"){
            max-width: 90%;
            img{
                max-width: 100%;
            }
        }
        .block{
            &-container{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;

                &.image-section{
                    flex-wrap: nowrap;
                    align-items: center;
                    gap: 20px;

                    img{
                        max-width: 60%;
                    }

                    img:first-child{
                        max-width: 40%;

                        @include media("<980px"){
                            max-width: 310px;
                        }
                    }

                    @include media("<980px"){
                        flex-wrap: wrap;
                        gap: 20px;

                        img{
                            max-width: 310px;
                        }
                    }

                    @include media("<tablet"){
                        margin-top: 20px;
                    }
                }

                &.text-image-section{
                    margin: 0;
                    align-items: center;

                    img{
                        width: 30%;
                    }

                    .block-container-itemBottom{
                        width: 70%;
                        img{
                            width: 100%;
                        }
                    }
                }

                &.repeated-blocks-section{
                    gap: 16px;
                }

                &-item{
                    all: unset;
                    cursor: pointer;
                    max-width: 165px;

                    &:hover{
                        .offer-icon{
                            background-color: $primary-color
                        }

                        h3{
                            color: $primary-color;
                        }

                        p{
                            color: $primary-color;
                        }
                    }

                    .offer-icon{
                        width: 40px;
                        height: 40px;
                        border-radius: 10px;
                        background-color: #7c53ff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: background-color .3s ease-out;
                    }

                    h3{
                        font-size: 20px;
                        font-weight: 700;
                        color: #7c53ff;
                        transition: color .3s ease-out;
                    }

                    p{
                        font-size: 14px;
                        font-weight: 400;
                        color: #6E6E6E;
                        transition: color .3s ease-out;
                    }

                    &Bottom{
                        width: min-content;
                        color: #6E6E6E;

                        @include media("<tablet"){
                            width: 100%;
                            padding: 0 10px;

                            img{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    &-right{
        background-color: $primary-color;
        width: 44%;
        padding: 15px;
        color: white;
        position: relative;
        @include media("<tablet"){
            width: 100%;
        }
        .hubspot-form{
            position: sticky;
            top: 170px;

            h1, h2, h3, h4, h5{
                text-transform: uppercase;
                font-family: "bignoodletitlingregular", Arial, sans-serif;
                font-size: 30px;
            }
        }
    }
}
