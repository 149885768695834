.presentation-container {
  margin: 30px auto;
  max-width: 980px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @include media("<desktop"){
    flex-direction: column;
  }
  &-title{
    color: $grey;
    font-weight: bold;
    max-width: 500px;
    h1, h2, h3{
      font-size: 40px;
      font-weight: bold;
      @include media('<tablet'){
        font-size: 30px;
      }
      span{
        color: $primary-color;
      }
    }
  }
  &-image{
    @include media("<desktop"){
      max-width: 100%;
      img{
        max-width: 100%;
      }
    }
  }
}

@include media('>=desktop'){
  .presentation-container-head {
    background-image: url('../../images/ordinateur.png');
  }

  /*.offres-logiciel-container-img {
    background-image: url('../../images/offres_logiciel_big.png');
    width: 980px;
    height: 376px;
  }*/
}

.fonctionnalites-container-section-article-readmore{
  color: $primary-color;
}


@include media('>=tablet','<desktop'){
  /*.offres-logiciel-container-img {
    background-image: url('../../images/offres_logiciel_medium.png');
    width: 500px;
    height: 333px;
  }*/
}


@include media('<tablet'){
  /*.offres-logiciel-container-img {
    background-image: url('../../images/offres_logiciel_small.png');
    width: 300px;
    height: 303px;
  }*/
}
