.plusDinfos {
  width: 100%;
  z-index: 2;
  position: fixed;
  bottom: 0;
}

.pricing-wrapper{
  background-color: #374857;
  padding-bottom: 40px;
  padding-top: 40px;
}

.presentation-container {
  margin: auto;
  position: relative;
  width: 950px;
  height: 260px;
}

.presentation-container-article-text {
  color: #949494;
  font-family: Arial, sans-serif;
}

.presentation-container-article-titre {
  color: #949494;
  font-family: Arial, sans-serif;
  font-size: 72px;
  font-weight: 100;
  margin: 0;
}

.presentation-container-article-text {
  color: #949494;
  font-family: Arial, sans-serif;
  font-size: 28px;
  margin: 0;
}


.presentation-container-choix-container-ordinateur {
  position: absolute;
  right: 0;
  top: 25px;
}

.presentation-container-choix-container-ban {
  width: auto;
  max-width: 980px;
  height: auto;
}





.presentation-container-solution-container {
  width: 980px;
}

.presentation-container-solution-container-titre {
  color: #949494;
  font-family: Arial, sans-serif;
  font-size: 35px;
  line-height: 1;
  font-weight: 100;
  margin: 0;
  background-color: #374857;
}

.presentation-container-solution-container-multiplateforme-container {
  display: inline-block;
  height: 114px;
  padding-top: 50px;
  vertical-align: top;
}

.presentation-container-solution-container-liste {
  display: inline-block;
}

.presentation-container-solution-container-liste-element {
  list-style: none;
  font-weight: bold;
  margin-top: 10px;
}

.presentation-container-solution-container-liste-element-liste {
  padding: 0;
  font-weight: normal;
}

.presentation-container-solution-container-liste-element-liste-element {
  color: #7A7A7A;
  list-style: none;
}

.presentation-container-solution-container-liste-element-liste-element:before {
  content: "+";
  margin: 8px;
}

.presentation-container-solution-container-souris {
  position: absolute;
  right: 0;
  top: 0px;
}


.m-pricing-wrapper {
  display: none;
}

.pricing-wrapper {
  width: 100%;
  background-color: #374857;
}

.pricing-table-offer-table {
  width: 100%;
  border-spacing: 0 !important;
  padding: 0;
  position: relative;
}

.pricing-table-offer:nth-child(1),
.pricing-table-offer:nth-child(2),
.pricing-table-offer:nth-child(3) {
  border-right: 1px #ffffff solid;
}

.pricing-table-offer {
  width: 25%;
}

.pricing-table-offer-table-img {
  background-color: #ffffff;
  text-align: center;
  padding-top: 5px;
  padding-top: -10px;
  font-family: Arial, sans-serif;
}

.pricing-table-offer-table-price {
  font-family: "bignoodletitlingregular", Arial, sans-serif;
  text-align: center;
  font-size: 25px;
  background-color: #ffffff;
  color: gray;
  height: 120px;
  padding-bottom: 15px;
}

.pricing-table-offer-table-price-from {
  height: 25px;
}

.pricing-table-offer-table-price-price {
  height: 40px;
}

.pricing-table-offer-table-price-number {
  font-size: 46px;
  line-height : 15px;
}

.pricing-table-offer-table-info {
  text-align: center;
}

.pricing-table-offer-table-button {
  position: absolute;
  border: none;
  font-family: "bignoodletitlingregular", Arial, sans-serif;
  color: #FFFFFF;
  font-size: 22px;
  padding-top: 5px;
  bottom: -12px;
  left: 15%;
  width: 70%;
  text-align: center;

}





.pricing-table-included-table {
  width: 100%;
  background-color: #FFFFFF;
  margin-top: 45px;
}

.pricing-table-included-table-title {
  background-repeat: repeat-x;
  background-position: 0 41%;
  border-left: 12px #FFFFFF solid;
  border-right: 12px #FFFFFF solid;
  text-align: left;
  padding-left: 60px;
}

.pricing-table-included-table-title-text {
  background-color: #FFFFFF;
  color: gray;
  display: inline-block;
  padding: 5px;
  font-family: "bignoodletitlingregular", Arial, sans-serif;
  font-size: 30px;
}

.pricing-table-included-table-list-td {
  width: 50%;
  vertical-align: top;
}

.pricing-table-included-table-list {
  padding-left: 80px;
  color: #617381;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.pricing-table-included-table-list-compatibilite {
  width: 100%;
  vertical-align: top;
  padding-bottom: 10px;
}



.princing-table-beneficient {
  border-spacing: 0 !important;
}


.princing-table-beneficient-table-title-text {
  color: gray;
  background-color: #ffffff;
  display: inline-block;
  padding: 5px;
  font-family: "bignoodletitlingregular", Arial, sans-serif;
  font-size: 30px;
  text-align: left;
  padding-left: 70px;
}

.princing-table-beneficient-table-moovBox-title {
  border-left: 12px white solid;
  border-right: 12px white solid;
}

.princing-table-beneficient-table-moovBox-banner {
  color: gray;
  width: 100%;
}

.princing-table-beneficient-table-moovBox-title {
  background-color: #FFFFFF;
  color: gray;
  display: inline-block;
  padding-top: 15px;
  font-family: Arial, sans-serif;
  font-size: 27px;
  padding-left: 50px;
}



.princing-table-beneficient-table-argument-text {
  padding-left: 60px;
  vertical-align: top;
  padding-top: 10px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #617381;
}








.princing-table-onlineStorage-title,
.princing-table-rent-title {
  color: #000000;
  text-align: left;
  background-color: #e8e8e8;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 18px;
  padding-left: 25px;
  padding-top: 20px;
}

.princing-table-onlineStorage-text,
.princing-table-rent-text {
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 30px;
  font-family: Arial, sans-serif;
  background-color: #e8e8e8;
  font-size: 15px;
  color: #000000;
}


.pricing-container-promo-container {
  position: absolute;
  top: 0px;
  right: 0px;
}

.pricing-container-promo-container img.medium{
  display: none;
}

.plusDinfos.offer-focus{
  position: relative;
  top: -700px;
}

.plusDinfos.offer-focus .plusDinfos-close {
  display: block;
}

.plusDinfos.item-active {
  position: absolute;
  height: 130px;
}

.plusDinfos.offer-focus .plusDinfos-close {
  display: block;
}

.plusDinfos.item-active .plusDinfos-close {
  display: block;
}




/* Generic List table grid */
.sidebar-pricing-detail a.more {
  background: $primary-color;
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  text-decoration: none;
  font-family: "bignoodletitlingregular", arial, sans-serif;
  letter-spacing: 0.5px;
  display: block;
  padding: 9px 0 2px;
  text-align: center;
  border-radius: 2px;
  width: 100%;
  margin-top: auto;
  transition: all .75s;
}

.sidebar-pricing-detail a.more:hover {
  background: #000;
}


/* List table grid */

.pricing-container-flex {
  max-width: 980px;
  width: 100%;
  margin: auto;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.new-pricing-table.pricing-table {
  background-color: #ffffff;
  padding: 30px;
  width: 700px;
}

.new-pricing-table table.white-bg {
}

.new-pricing-table .platforms-os {
  display: flex;
  align-items: center;
  padding: 30px 56px 0 54px;
}

.new-pricing-table .platforms-os .item-platform {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.new-pricing-table .platforms-os .item-platform:first-child {
  margin-left: 0;
}

.new-pricing-table .platforms-os .item-platform svg {
  width: 24px;
  margin-right: 10px;
}

.new-pricing-table .platforms-os .item-platform span {
  font-family: Arial, sans-serif;
  color: #8a9ca4;
  font-size: 14px;
}

.new-pricing-table .offre-title {
  padding: 15px 0 5px;
  border: 1px solid #cbd7dc;
}

.new-pricing-table .offre-title p {
  font-family: Arial, sans-serif;
  color: #929292;
  font-size: 14px;
  margin: 0;
  line-height: 1.1;
}

.new-pricing-table .offre-title .type-offer {
  font-family: "bignoodletitlingregular", sans-serif;
  color: #000;
  font-size: 17px;
}

.new-pricing-table .offre-title .big {
  font-family: "bignoodletitlingregular", sans-serif;
  font-size: 22px;
}

.new-pricing-table .offre-title p.price {
  font-size: 35px;
  font-weight: bold;
  margin: 0;
}

.new-pricing-table .offre-title p.price sup {
  font-size: 16px;
  top: -0.8em;
}

.new-pricing-table .offre-title p.price span.month {
  color: #000000;
  font-size: 14px;
  margin: 0 0 0 -15px;
}

.new-pricing-table tr.title-section {
  background: rgba(203, 215, 220, 0.2);
  font-family: "bignoodletitlingregular", sans-serif;
  font-size: 18px;
  color: #8a9ca4;
}

.new-pricing-table tr.title-section td {
  padding: 12px 0 6px 20px;
}

.new-pricing-table .white-bg td {
  border: 1px solid #cbd7dc;
}

.new-pricing-table .white-bg a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  width: 100%;
}

.new-pricing-table .white-bg .toggle-text {

}

.new-pricing-table .white-bg svg {
  width: 10px;
}

.new-pricing-table .toggle.white-bg {
  display: flex;
  padding: 5px 3px 5px 20px;
  min-height: 30px;
  font-size: 14px;
}

.new-pricing-table .tick {
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-pricing-table .tick svg {
  width: 20px;
}

.new-pricing-table .toggle.orange-bg {
  background-color: rgba(244, 247, 248, 0.6);
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  width: 100%;
  padding: 15px 20px;
}

.new-pricing-table .toggle.orange-bg p {
  margin: 0;
}

.new-pricing-table .wrapper-link {
  text-align: center;
  margin: 30px 0 0;
}

.new-pricing-table .wrapper-link a.back-offer {
  font-size: 20px;
  font-family: "bignoodletitlingregular", arial, sans-serif;
  color: #5f5f5f;
  text-transform: uppercase;
}

.new-pricing-table .wrapper-link a.back-offer:hover {
  text-decoration: none;
}

.sidebar-pricing-detail {
  width: 250px;
  position: relative;
}

.sidebar-pricing-detail .listing-lecteur-sesam {
  background-image: linear-gradient(0, #e4e9ec, #f7f7f7);
  padding: 35px 25px 20px;
}

.sidebar-pricing-detail .listing-lecteur-sesam > p {
  font-size: 14px;
  line-height: 1.2;
  margin: 0 0 10px;
  text-align: center;
  color: #929292;
}

.sidebar-pricing-detail .listing-lecteur-sesam h2 {
  font-family: "bignoodletitlingregular", arial, sans-serif;
  font-size: 25px;
  text-transform: uppercase;
  text-align: center;
  font-weight: normal;
  margin: 0;
}

.sidebar-pricing-detail .listing-lecteur-sesam .wrapper-lecteurs {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 0;
  flex-wrap: wrap;
}

.sidebar-pricing-detail .listing-lecteur-sesam .wrapper-lecteurs .item-lecteur {
  padding: 20px 25px 30px;
  border: 1px solid #cbd7dc;
  border-radius: 3px;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 20px;
}

.sidebar-pricing-detail .listing-lecteur-sesam .wrapper-lecteurs .item-lecteur p.title {
  font-family: "bignoodletitlingregular", arial, sans-serif;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  margin: 0 0 20px;
  color: #5f5f5f;
}

.sidebar-pricing-detail .listing-lecteur-sesam .wrapper-lecteurs .item-lecteur img {
  max-width: 100%;
  height: 95px;
  object-fit: contain;
}

.sidebar-pricing-detail .listing-lecteur-sesam .wrapper-lecteurs .item-lecteur p.price {
  font-size: 60px;
  font-weight: bold;
  margin: 10px 0;
}

.sidebar-pricing-detail .listing-lecteur-sesam .wrapper-lecteurs .item-lecteur p.price sup {
  font-size: 35px;
}

.sidebar-pricing-detail .listing-lecteur-sesam .wrapper-lecteurs .item-lecteur p.price span.month {
  color: #000000;
  font-size: 22px;
  margin: 0 0 0 -33px;
}

.sidebar-pricing-detail .install-accompagment {
  background: url('../../images/img_call.png');
  background-size: cover;
  padding: 20px 20px;
  height: 423px;
  margin: 30px 0 0;
  box-sizing: border-box;
}

.sidebar-pricing-detail .install-accompagment p {
  font-size: 20px;
  line-height: 1.3;
  color: #000;
  font-weight: bold;
  margin: 0;
}

.sidebar-pricing-detail .logo-agree {
  text-align: center;
  margin: 30px 0;
}






@include media('>=desktop'){
  //desktop styles
}


@include media('>=tablet','<desktop'){
}


@include media('<desktop'){

  .pricing-container-flex {
    flex-wrap: wrap;
  }

  .new-pricing-table.pricing-table {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    max-width: inherit;
  }

  .pricing-container-promo-container {
    width: 100% !important;
  }

  .sidebar-pricing-detail .listing-lecteur-sesam .wrapper-lecteurs {
    flex-wrap: wrap;
  }

  .sidebar-pricing-detail .listing-lecteur-sesam .wrapper-lecteurs .item-lecteur {
    width: 48%;
    max-width: inherit;
  }

  .sidebar-pricing-detail .listing-lecteur-sesam .wrapper-lecteurs .item-lecteur p.title,
  .sidebar-pricing-detail .listing-lecteur-sesam .wrapper-lecteurs .item-lecteur p.price{
    width: 100%;
  }
  .sidebar-pricing-detail .listing-lecteur-sesam .wrapper-lecteurs .item-lecteur p.price{
    text-align: center;
  }

  .sidebar-pricing-detail .install-accompagment {
    display: none;
  }


  /*=================================================
===============SECTION PRESENTATION================
================================================*/

  .presentation-container {
    padding: 10px 3%;
    color: #949494;
    font-size: 18px;
  }

  .presentation-container-head-title {
    font-weight: 100;
    margin: 0;
    font-size: 42px;
  }

  .presentation-container-solution-container-titre {
    font-weight: 100;
    font-size: 42px;
  }

  .presentation-container-solution-container-multiplateforme-container {
    width: 69px;
    float: left;
    margin-top: 22px;
  }

  .presentation-container-solution-container-liste {
    list-style: none;
    color: #000000;
    width: calc(100% - 70px);
    display: inline-block;
    margin-top: 0;
  }

  .presentation-container-solution-container-ordinateur {
    display: none;
  }


  .presentation-container-choix-container-ban {
    width: auto;
    max-width: 600px;
    height: auto;
  }




















  /*=================================================
  ==================SECTION PRICING==================
  ================================================*/

  .m-pricing-wrapper{
    display: none;
  }

  .pricing-wrapper {
    margin-top: 0px;
    padding: 40px 3%;
    background-color: #374857;
  }

  .pricing-table {
    width: 100%;
    max-width: 750px;
    margin: auto;
  }

  .pricing-table-offer {
    width: 25%;
    vertical-align: top;
    border-right: 1px #ffffff solid;
    text-align: center;
  }

  .pricing-table-offer:last-child {
    border: none;
  }

  .pricing-table-offer-table {
    background-color: #ffffff;
  }

  .pricing-table-offer-table-img {
    background-color: #FFFFFF;
    width: 25%;
    color: #9c9c9c;
  }

  .pricing-table-offer-table-price {
    padding: 8px;
    height: 150px;
    position: relative;
  }

  .pricing-table-offer-table-price-from,
  .pricing-table-offer-table-price-price,
  .pricing-table-offer-table-price-praticien {
    font-family: "bignoodletitlingregular";
    color: gray;
    font-size: 20px;
  }

  .pricing-table-offer-table-price-number {
    font-size: 42px;
  }

  .pricing-table-offer-table-button {
    font-family: "bignoodletitlingregular";
    color: #FFFFFF;
    border: none;
    padding-top: 5px;
    font-size: 20px;
    position: absolute;
    width: 100px;
    left: calc(50% - 50px);
    bottom: -14px;
    box-shadow: 0 0 12px #000000;
  }



  .pricing-table-included {
    border-top: 10px #ffffff solid;
    background-color: #FFFFFF;
  }

  .pricing-table-included-table {
    width: 100%;
  }

  .pricing-table-included-table-title {
    padding-top: 15px;
    background-image: url('../../images/separator.png');
    background-repeat: repeat-x;
    background-position: 0 50%;
    font-family: "bignoodletitlingregular";
    color: gray;
    font-size: 25px;
    border-left: 8px #ffffff solid;
    border-right: 8px #ffffff solid;
  }

  .pricing-table-included-table-title-text {
    display: inline-block;
    padding: 0 8px;
    background-color: #ffffff;
  }

  .pricing-table-included-table-list-td {
    width: 50%;
    vertical-align: top;
  }

  .pricing-table-included-table-list {
    padding-left: 30px;
    color: #617381;
  }

  .pricing-table-included-table-list-compatibilite {
    width: 100%;
    vertical-align: top;
  }

  .princing-table-beneficient,
  .princing-table-beneficient tbody {
    width: 100%;
    border-spacing: 0 !important;
  }

  .princing-table-beneficient-table {
    background-color: #ffffff;
    border-spacing: 0 !important;
    width: 100%;
  }

  .princing-table-beneficient-table-row {
    background-image: url(../../images/separator.png);
    background-repeat: repeat-x;
    background-position: 0 50%;
    font-family: "bignoodletitlingregular";
    font-size: 25px;
    color: gray;
    border-left: 8px #ffffff solid;
    border-right: 8px #ffffff solid;
  }


  .princing-table-beneficient-table-title {
    border-spacing: 0 !important;
  }

  .princing-table-beneficient-table-title-text {
    display: inline-block;
    padding: 8px 5px 0px;
    background-color: #ffffff;
  }

  .princing-table-beneficient-table-moovBox-title {
    padding: 10px;
    padding-bottom: 4px;
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 24px;
    color: gray;
  }


  .princing-table-beneficient-table-moovBox-banner {
    background-color: #ffffff;
    width: 100%;
    border-left: 8px #ffffff solid;
    border-right: 7px #ffffff solid;
  }

  .princing-table-beneficient-table-argument {
    padding: 10px 0;
    display: block;
  }

  .princing-table-beneficient-table-argument-text {
    padding: 0 10px;
    width: 60%;
    color: #617381;
  }

  .princing-table-onlineStorage-title,
  .princing-table-rent-title {
    padding: 8px;
    font-weight: normal;
    background-color: #899098;
    color: #ffffff;
  }

  .princing-table-onlineStorage-text,
  .princing-table-rent-text {
    padding: 8px;
    font-weight: normal;
    background-color: #ffffff;
    color: #617381;
  }

  .pricing-container-promo-container {
    display: block;
    width: 492px;
    margin: auto;
    margin-top: 20px;
  }

  .pricing-container-promo-container img.large{
    display: none;
  }

}

@include media('<tablet'){
  .sidebar-pricing-detail .listing-lecteur-sesam .wrapper-lecteurs .item-lecteur {
    width: 100%;
  }

  .sidebar-pricing-detail .listing-lecteur-sesam .wrapper-lecteurs .item-lecteur img {
    width: initial;
  }

  .mobile-wrapper {
    overflow: scroll;
  }

  .new-pricing-table table.white-bg {
    width: 700px;
  }

  /*=================================================
===============SECTION PRESENTATION================
================================================*/

  .pricing-wrapper {
    margin-top: 15px;
  }

  .presentation-container {
    padding: 10px 3%;
    color: #949494;
    font-size: 18px;
  }

  .presentation-container-head-title {
    font-weight: 100;
    margin: 0;
    font-size: 32px;
  }

  .presentation-container-solution-container-titre {
    font-weight: 100;
    font-size: 32px;
  }

  .presentation-container-solution-container-multiplateforme-container {
    width: 39px;
    float: left;
  }

  .presentation-container-solution-container-liste {
    list-style: none;
    color: #000000;
    width: calc(100% - 40px);
    display: inline-block;
    margin-top: 0;
  }

  .presentation-container-solution-container-ordinateur {
    display: none;
  }


  .presentation-container-choix-container-ban {
    width: 100%;
  }

  .pricing-table-beneficier-table-presentation-moovbox {
    display: none;
  }










  /*=================================================
  ==================SECTION PRICING==================
  ================================================*/

  .large-pricing-wrapper{
    display: none;
  }

  .pricing-wrapper {
    margin-top: 40px;
    padding: 40px 2%;
    background-color: #374857;
  }

  .pricing-container {
    width: 95%;
    max-width: 400px;
    margin: auto;
  }

  .pricing-table {
    width: 100%;
  }

  .pricing-table-picto-table,
  .pricing-table-offer-table,
  .pricing-table-separator-table,
  .pricing-table-inclus-table,
  .pricing-table-beneficier-table,
  .pricing-table-sauvegarde-table,
  .pricing-table-location-table {
    width: 100%;
  }

  .pricing-table-picto-table-image:nth-child(1) {
    border-right: 1px #ffffff solid;
  }

  .pricing-table-picto-table-image {
    width: 50%;
    text-align: center;
    color: #9c9c9c;
    background-color: #ffffff;
    padding-bottom: 5px;
  }

  .pricing-table-picto-table-image img {
    width: 137px;
    display: block;
    margin: auto;
  }

  .pricing-table-offer-table-price {
    width: 50%;
    text-align: center;
    padding: 18px 0;
    background-color: #ffffff;
    color: gray;
    font-family: "bignoodletitlingregular";
    position: relative;
  }

  .pricing-table-offer-table-button {
    position: absolute;
    color: #FFFFFF;
    border: none;
    padding-top: 7px;
    bottom: -10px;
    left: calc(50% - 42px);
    font-size: 20px;
  }

  .pricing-table-offer-table-from,
  .pricing-table-offer-table-practicien {
    font-size: 18px;
  }

  .pricing-table-offer-table-number {
    font-size: 38px;
  }

  .pricing-table-offer-table-price:nth-child(1) {
    border-right: 1px #ffffff solid;
  }

  .pricing-table-separator-table-separator {
    width: 50%;
    height: 50px;
    background-color: #ffffff;
  }

  .pricing-table-separator-table-separator:nth-child(1) {
    border-right: 1px #ffffff solid;
  }

  .pricing-table-inclus {
    padding-top: 30px;
    background-color: #EFEFED;
    border-top: 8px #ffffff solid;
  }

  .pricing-table-inclus-table-titre-container {
    margin: 0 10px;
    background-image: url(../../images/separator.png);
    background-repeat: repeat-x;
    background-position: 0 40%;
  }

  .pricing-table-inclus-table-titre {
    background-color: #EFEFED;
    padding: 0 5px;
    font-family: "bignoodletitlingregular";
    font-size: 20px;
  }

  .pricing-table-inclus-table-liste {
    color: #617381;
  }

  .pricing-table-included-table-list-compatibilite {
    width: 100%;
    vertical-align: top;
  }

  .pricing-table-beneficier {
    padding-top: 10px;
    background-color: #ffffff;
  }

  .pricing-table-beneficier-table-titre-container {
    margin: 0 10px;
    background-image: url(../../images/separator.png);
    background-repeat: repeat-x;
    background-position: 0 40%;
  }

  .pricing-table-beneficier-table-titre {
    background-color: #ffffff;
    padding: 0 5px;
    font-family: "bignoodletitlingregular";
    font-size: 20px;
  }

  .pricing-table-beneficier-table-moovbox {
    padding: 10px 8px 2px;
    background-color: #ffffff;
  }

  .pricing-table-beneficier-table-moovbox-titre {
    color: gray;
    font-family: Arial, sans-serif;
    font-size: 24px;
  }

  .pricing-table-beneficier-table-presentation {
    padding: 10px 20px;
    color: #617381;
  }

  .pricing-table-sauvegarde-table-head,
  .pricing-table-location-table-head {
    background-color: #899098;
    padding: 10px;
    color: #ffffff;
    font-weight: normal;
  }

  .pricing-table-sauvegarde-table-text,
  .pricing-table-location-table-text {
    background-color: #ffffff;
    padding: 10px;
  }

  .pricing-container-promo-container {
    width: 95%;
    max-width: 400px;
    margin: auto;
    margin-top: 20px;
  }

  .pricing-container-promo-container img {
    width: 247px;
    display: block;
    margin: auto;
  }
}
