@include media('>=desktop'){
  /*.plusDinfos {
    position: absolute;
    top:537px;
    bottom: auto;
  }*/

  /*.plusDinfos.skrollable-after {
    position: fixed;
    top: auto;
    bottom: 0;
  }*/

  .carouselContainer {
    margin: auto;
    /*margin-top: 150px;
    margin-bottom: 140px;*/
    width: 980px;
  }

  .annivContainer {
    margin: auto;
    margin-bottom: 70px;
    width: 300px;
    text-align: center;
  }

  .carouselContainer-item-offre_bienvenue{
    position: relative;
  }

  /*.carouselContainer-item-offre_bienvenue-trigger{
      position: absolute;
      top: 225px;
      left: 570px;
      display: block;
      width: 340px;
      height: 40px;
      cursor: pointer;
  }*/

  .carouselContainer-item-facilite{
    position: relative;
  }

  /*.carouselContainer-item-facilite-trigger{
      position: absolute;
      top: 305px;
      left: 600px;
      display: block;
      width: 320px;
      height: 40px;
      cursor: pointer;
  }*/

  .carouselContainer-frame {
    /*margin-left: 200px;
    margin-top: 60px;*/
    position: relative;
  }

  .carouselContainer-frame-image {
    margin: 0;
  }

  #owl-demo .item img{
    display: block;
    width: 100%;
    height: auto;
  }

  .carouselContainer-frame-article {
    left: 340px;
    position: absolute;
    top: -60px;
  }

  .carouselContainer-frame-article-titre {
    color: #949494;
    font-family: Arial, sans-serif;
    font-size: 80px;
    font-weight: 100;
    margin: 0;
  }

  .carouselContainer-frame-article-texte {
    color: #949494;
    font-family: Arial, sans-serif;
    margin: 0;
  }

  .carouselContainer-vignette {
    position: relative;
    margin: 0;
    padding: 0;
    height: 0;
  }

  .carouselContainer-vignette-element {
    display: inline-block;
  }

  .carouselContainer-vignette-element-multiplateforme {
    position: absolute;
    top: -55px;
    left: 120px;

  }

  .hp-large{
    display:block;
  }
  .hp-mobile {
    display:none;
  }
  .hp-table-rent-row-video{
    padding-right: 15px;
  }

  .videosDemos {
    margin: auto;
    width: 980px;
    z-index: 1;
  }

  .videosDemos-titre {
    color: #949494;
    font-family: Arial, sans-serif;
    font-size: 66px;
    font-weight: 100;
    margin: 0;
  }

  .videosDemos-text {
    color: #949494;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    margin: 0;
    text-transform: uppercase;
  }

  .videosDemos-image {
    width: 100%;
  }

  .videosDemos-frameContainer{
    margin-top: 20px;
    text-align: center;
    z-index: 1;
  }
}


@include media('>=tablet','<desktop'){
  .videosDemos {
    width: 100%;
    padding: 10px 3%;
  }

  .videosDemos-titre {
    font-size: 42px;
    font-weight: 100;
    color: #949494;
    margin: 10px 0;
  }

  .videosDemos-text {
    color: #949494;
  }

  .videosDemos-frameContainer {
    width: 100%;
    text-align: center;
  }
  .videosDemos-frameContainer iframe {
    width: 100% !important;
    height: 490px !important;
  }


  .carouselContainer {
    display: none;
  }
  .annivContainer {
    display: none;
  }
  .guide-idel {
    display: none;
  }
  .anniv {
    display: none;
  }

  .hp-table-rent-row-video{
    width: 600px;
  }



  .hp-large{
    display:none;
  }
  .hp-mobile {
    display:block;
  }
  .videosDemos {
    margin: auto;
    width: 640px !important;
  }
  .hp-videosDemos-frameContainer iframe{
    width: 100% !important;
    height: 335px !important;
  }
}


@include media('<tablet'){
  .videosDemos {
    width: 100%;
    padding: 10px 3%;
  }

  .videosDemos-titre {
    font-size: 32px;
    font-weight: 100;
    color: #949494;
    margin: 10px 0;
  }

  .videosDemos-text {
    color: #949494;
  }

  .videosDemos-frameContainer {
    width: 100%;
    text-align: center;
  }
  .videosDemos-frameContainer iframe {
    width: 285px !important;
    height: 230px !important;
  }

  .carouselContainer {
    display: none;
  }


  .hp-large{
    display:none;
  }
  .hp-mobile {
    display:block;
  }
  .videosDemos {
    margin: auto;
    width: 100% !important;
  }
  .videosDemos-titre {
    font-size: 22px !important;
  }
  .hp-videosDemos-frameContainer iframe {
    width: 285px !important;
    height: 160px !important;
  }
}
