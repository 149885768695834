/*
// Typography

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.h {
	font-family: $base-font-family;
	font-weight: 800;
	margin: 0 0 16px;
	color: $black-100;
	text-transform: uppercase;
}

h1, .h1 {
	font-size: 40px;
	line-height: 1.6;
	font-weight: 800;
	text-transform: none;
	color: $black-100;
	text-align: center;

	@include tablet {
		font-size: 30px;
	}
}

h2, .h2 {
	color: $white;
	font-size: 35px;
	font-weight: 800;
	text-transform: none;
	line-height: 1.2;
	text-align: center;

	@include phone {
		font-size: 30px;
	}

	&.align-left {
		text-align: left;
	}
}

h3, .h3 {
	font-size: 25px;
	line-height: 1.2;
	text-align: center;
	font-weight: 800;
	text-transform: none;

	@include phone {
		font-size: 20px;
	}

	&.align-left {
		text-align: left;
	}
}

h4, .h4 {
	font-size: $h4-font-size;
}

h5, .h5 {
	font-size: $h5-font-size;
}

h6, .h6 {
	font-size: $h6-font-size;
}

p {
	margin: 0 0 23px;
}

a {
	color: $base-link-color;
	transition: color 0.3s linear, background 0.3s linear, opacity 0.3s linear, border 0.3s linear;
	text-decoration: none;
}*/
