html {
  font-family: arial, sans-serif;
  overflow-x: hidden;
}

html *{
  box-sizing: border-box;
}

a{
  background-color:transparent;
  color: inherit;
}

.navContainer-liste{
  #logo-home{
    fill: $primary-color;
    height: 30px;
    vertical-align: middle;
  }
}

@include media(">=desktop") {
  .body {
    position: relative;
    margin: 0;
  }

  .headerContainer {
    background-color: white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    border-bottom: 1px solid #DDDDDD;
  }

  .headerContainer-header {
    margin: auto;
    width: 980px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .headerContainer-header > * {
    line-height: 32px;
  }

  .headerContainer-header-logo {
    vertical-align: top;
    padding-right: 10px;
    border-right: 1px #ffffff solid;
    margin-right: 10px;
    //max-height: 23px;
  }

  .headerContainer-header-h1 {
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 10px;
    position: fixed;
    top: -100%;
  }

  .headerContainer-header-link {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-decoration: none;
    width: 100%;
  }

  .headerContainer-header-accroche {
    padding: 35px 0 25px 0;
    font-family: Arial, sans-serif;
    font-weight: 700;
    font-size: 15px;
    line-height: 1.15;

    span{
      color: $primary-color;
    }
  }

  .headerContainer-header-contactContainer {
    display: flex;
    align-items: center;

    a img{
      display: block;
    }
  }

  .headerContainer-header-contact {
    color: white;
    font-family: arial, sans-serif;
    font-size: 14px;
    padding: 5px 10px;
    line-height: 1;
    margin-right: 4px;
  }

  .headerContainer-header-form {
    display: inline-block;
    margin: 0;
    vertical-align: top;
  }

  .headerContainer-header-form-input {
    border: 1px #fff solid;
    width: 150px;
    font-size: 14px;
    margin-left: 8px;
  }

  .headerContainer-header-form-valider {
    border-bottom: none;
    color: #fff;
    cursor: pointer;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-weight: bolder;
    font-weight: 100;
    letter-spacing: 3px;
    margin-left: 11px;
  }

  .navContainer-nav {
    width: 980px;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }

  .navContainer {
    background-color: #fff;
    width: 100%;
    position: fixed;
    top: 78px;
    z-index: 2;
  }

  .navContainer-liste {
    display: inline-block;
    margin: 20px 0 20px 10px;
    padding: 0;
  }

  .navContainer-liste-element {
    display: inline-block;
    list-style: none;
    margin-right: 20px;
    &:last-child{
      margin-right: 0;
    }
  }

  .navContainer-liste-element-lien {
    color: #000;
    font-family: Arial, sans-serif;
    font-size: 15px;
    font-weight: 700;
    text-decoration: none;
  }

  .navContainer-contact{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
  }

  .navContainer-contact-item{
    font-family: Arial, sans-serif;
    font-size: 15px;
    font-weight: 700;
    padding: 10px 15px;
    border: 1px solid #000000;
    border-radius: 5px;
    text-decoration: none;
  }


  #page_content_container {
    padding-top: 130px;
    margin-bottom: 85px;
  }

  .handler_message_container {
    color: white;
    padding: 10px 0px;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
  }

  .handler_message_container.success {

  }

  .handler_message_container.error {

  }

  .handler_message_container h3 {
    margin: 0;
    font-size: 32px;
    font-weight: 300;
  }

  .handler_message_container div {
    font-size: 20px;
  }


  .plusDinfos {
    width: 100%;
    z-index: 2;

    &.is-sticky{
      position: fixed;
      z-index: 10;
      bottom: 0;
    }

    &.item-active{
      position: absolute;
      bottom: auto;

      .plusDinfos-close {
        display: block;
      }
    }
  }

  .plusDinfos-980 {
    position: relative;
    margin: auto;
    padding-top: 25px;
    padding-bottom: 15px;
    width: 980px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .plusDinfos-titre {
    color: #fff;
    display: inline-block;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 32px;
    font-weight: 300;
    letter-spacing: 1px;
    margin: 0;
    margin-top: 1px;
    text-transform: uppercase;
  }

  .plusDinfos-form {
    display: inline-block;
    margin: 0;
    margin-left: 10px;
    margin-top: -35px;
    vertical-align: top;

    *[class^="boltform"] ~ & {
      margin-top: 0;
    }
  }

  .plusDinfos-form-input {
    border: 1px #fff solid;
    margin-right: 10px;
  }

  .plusDinfos-form-valider {
    background-color: #000;
    border: 5px #000 solid;
    border-bottom: 1px #000 solid;
    border-top: 4px #000 solid;
    color: #fff;
    cursor: pointer;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
  }

  .plusDinfos-telephone {
    color: #fff;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 24px;
    font-weight: 300;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
  }

  .plusDinfos-close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: white;
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
    display: none;
  }

  .filDactu {
    margin-top: 100px;
    padding: 0;
    position: relative;
  }

  .filDactu-titre {
    color: #e7e7e7;
    font-family: Arial, sans-serif;
    font-size: 64px;
    font-weight: 100;
    margin: 0;
    position: absolute;
    right: 0;
    top: -58px;
  }

  .filDactu-idel {
    background-color: #e7e7e7;
  }

  .filDactu-titreContainer-980 {
    margin: auto;
    padding: 10px 0;
    position: relative;
    width: 980px;
  }

  .filDactu-idel-980 {
    margin: auto;
    position: relative;
    width: 980px;
  }

  .filDactu-idel-titre {
    color: #273846;
    display: inline-block;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 32px;
    /*font-weight: 400;*/
    margin: 0;
    text-transform: uppercase;
    height: 90px;
    vertical-align: top;
    width: 360px;
    line-height: 70px;
  }

  .filDactu-idel-titre-lien {
    color: #56748e;
  }

  .filDactu-idel-news {
    display: inline-block;
    margin-bottom: 12px;
    margin-left: 10px;
    margin-top: 12px;
    padding-left: 70px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    position: relative;
    width: 350px;
    min-height: 70px;

    background-image: url('../../images/rounded_plus.png');
    background-repeat: no-repeat;
    background-position: 5px center;

    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    -ms-transition: all 400ms;
    -o-transition: all 400ms;
    transition: all 400ms;
  }

  .filDactu-idel-news:hover {
    background-color: rgba(199, 199, 199, 1);
  }

  .filDactu-idel-news-table {
    width: 100%;
  }

  .filDactu-idel-news-border {
    position: absolute;
    width: 1px;
    background-color: #c7c7c7;
    left: 47px;
    top: 0;
    bottom: 0;
  }

  .filDactu-idel-news-lirePlus {
    text-decoration: none;
    color: #6E6E6E;
  }
  /*
  .filDactu-idel-news-lirePlus:hover {
      background-position: 0 60px;
  }

  .filDactu-idel-news-lirePlus:active {
      background-position: 0 30px;
  }
  */
  .filDactu-idel-news-table td:last-child {
    width: 70px;
  }

  .filDactu-idel-news-title {
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 20px;
    margin: 0;
  }

  .filDactu-idel-news-date {
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 20px;
    margin: 0;
  }

  .filDactu-idel-news-text {
    font-family: Arial, sans-serif;
    font-size: 14px;
    margin: 0;
    text-align: justify;
    max-height: 35px;
  }


  .filDactu-newsletter {
    background-repeat: no-repeat;
    /*height: 400px;*/
    padding: 20px;
    position: absolute;
    right: 0;
    top: 10px;
    width: 222px;
    text-align: center;
    z-index: 1;
    min-height: 285px;
  }

  .filDactu-newsletter-titre {
    color: #fff;
    display: inline-block;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 24px;
    font-weight: 300;
    margin: 0;
    margin-top: 20px;
    text-align: center;
    text-transform: uppercase;
  }

  .filDactu-newsletter-form {
    text-align: center;
  }

  .filDactu-newsletter-form-input {
    margin: 20px 0;
    text-align: center;
    width: 170px;
  }

  .filDactu-newsletter-form-valider {
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 20px;
    text-decoration: underline;
    text-transform: uppercase;
  }

  .container-filDactu-toutesActus {
    margin: 15px auto 5px auto;
    width: 249px;
  }

  .filDactu-toutesActus {
    color: #000;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;

  }

  .container-filDactu-spacer {
    height: 25px;
  }

  .filDactu-cecilio-container {
    background-color: #e7e7e7;
    padding-top: 40px;
  }

  .filDactu-cecilio {
    margin: auto;
    width: 980px;
  }

  .filDactu-cecilio-infirmiere {
    clear: both;
    float: left;
    margin-right: 50px;
  }

  .filDactu-cecilio-titre {
    color: #273846;
    display: inline-block;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 32px;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
    width: 400px;
  }

  .filDactu-cecilio-titre-lien {
    color: #56748e;
  }

  .filDactu-cecilio-news {
    display: inline-block;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 5px 5px 5px 70px;

    position: relative;
    width: 500px;
    background-image: url('../../images/rounded_plus.png');
    background-repeat: no-repeat;
    background-position: 5px center;

    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    -ms-transition: all 400ms;
    -o-transition: all 400ms;
    transition: all 400ms;

  }

  .filDactu-cecilio-news:hover {
    background-color: rgba(199, 199, 199, 1);
  }


  .filDactu-cecilio-news-border {
    position: absolute;
    width: 1px;
    background-color: #c7c7c7;
    left: 47px;
    top: 0;
    bottom: 0;
  }

  .filDactu-cecilio-news-lirePlus {
  }

  /*.filDactu-infirLib-news-lirePlus:hover {
      background-position: 0 60px;
  }

  .filDactu-infirLib-news-lirePlus:active {
      background-position: 0 30px;
  }*/

  .filDactu-cecilio-news-titre {
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 20px;
    margin: 0;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .filDactu-cecilio-news-text {
    color: #6E6E6E;
    font-family: Arial, sans-serif;
    font-size: 14px;
    margin: 0;
    text-align: justify;
  }

  .plusDoptions {
    margin: auto;
    margin-top: 80px;
    width: 980px;
    clear: both;
  }

  .plusDoptions-article {
    display: inline-block;
    margin: 0 4.8%;
    width: 40%;
    vertical-align: top;
    cursor: pointer;
  }

  .plusDoptions-image {
    display: block;
    margin: auto;
    width: 72px;
  }

  .plusDoptions-titre {
    color: #6A6A6A;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-weight: 100;
    text-align: center;
    text-transform: uppercase;
  }

  .plusDoptions-text {
    color: #4F4F4F;
    font-family: Arial, sans-serif;
    font-size: 12px;
    text-align: justify;
  }

  .connexion {
    height: 36px;
    margin-top: 150px;
    padding-top: 5px;
  }

  .connexion-980 {
    margin: auto;
    position: relative;
    width: 980px;
  }

  .connexion-titre {
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    margin: 0;
    position: absolute;
    text-transform: uppercase;
    top: -36px;
  }

  .connexion-form-input {
    border: 1px #fff solid;
  }

  .connexion-form-valider {
    background-color: #000;
    border: 4px #000 solid;
    border-bottom: none;
    color: #fff;
    cursor: pointer;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
  }


  .footer {
    background-color: #374857;
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .footer-980 {
    margin: auto;
    position: relative;
    width: 980px;
  }

  .footer-image {
    left: 0;
    position: absolute;
    top: 42px;
  }

  .footer-text {
    border-left: 1px #A4A8AB solid;
    color: #A4A8AB;
    display: inline-block;
    font-family: Arial, sans-serif;
    margin-left: 214px;
    padding: 8px 0;
    padding-left: 30px;
  }

  .footer-text-call {
    color: #ffffff;
    display: inline-block;
    font-family: Arial, sans-serif;
    margin-left: 350px;
    padding: 8px 0;
    padding-left: 30px;
    text-decoration: none;
    margin-right: 150px;
    font-weight: 600;
  }

  .footer-text-call input.plusDinfos-form-input {
    margin: 0 5px;
  }

  .superFooter {
    background-color: #1A1A1A;
    padding-bottom: 120px;
    padding-top: 20px;
  }

  .superFooter-980 {
    margin: auto;
    width: 980px;
  }

  .superFooter-liste {
    display: flex;
    flex-direction: column;
    height: 120px;
    flex-wrap: wrap;
    padding: 0;
    width: 100%;
  }

  .superFooter-liste-element {
    list-style: none;
    white-space: nowrap;
  }

  .superFooter-liste-element-lien {
    color: #444444;
    font-family: Arial, sans-serif;
    font-size: 12px;
    text-decoration: none;
  }

  .superFooter-liste-element:nth-child(-n+6) .superFooter-liste-element-lien {
    color: #b4b4b4;
  }

  img.pop-parainage {
    width: 100%;
    max-width: 600px;
  }

  .popupContainer-contentContainer h4,
  .popupContainer-contentContainer p {
    padding-left: 10px;
  }

  .popupContainer {
    position: relative;
    background: #FFF;
    width: auto;
    max-width: 600px;
    margin: 20px auto;
    font-family: Arial, sans-serif;
    font-size: 12px;
    z-index: 1000;
  }

  .popupContainer-title {
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    text-transform: uppercase;
    margin: 0;
    padding: 7px 10px 0;
    color: white;
    letter-spacing: 1px;
    font-weight: 100;
    font-size: 30px;
  }

  .popupContainer-subtitle {
    font-size: 17px;
    margin: 15px 0;
  }

  .popupContainer-subtitle.error {
    border: 2px dashed #ff243a;
    padding: 15px;
    color: #ff243a;
  }

  .popupContainer-subtitle.success {
    border: 1px dashed #27a230;
    padding: 15px;
    color: #27a230;
  }

  .popupContainer-subtitle:last-child {
    margin-bottom: 5px;
  }

  .popupContainer-contentContainer {
    color: #4F4F4F;
  }

  .popupContainer-contentContainer p {
    margin: 0 0 10px;
  }

  .popupContainer-form {
    padding: 7px 10px;
    position: relative;
    margin-top: -3px;
  }

  #popupParrainage .popupContainer-form {
    display: inline-block;
    height: 381px;
  }

  .popupContainer-form-row {
    display: flex;
    align-items: center;
    margin: 5px auto;
    flex-wrap: wrap;
    max-width: 320px;

    .label-container {
      font-weight: bold;
      width: 100px;
    }

    input[type="text"], input[type="email"]{
      margin-left: auto;
      border: 0;
      padding: 0 5px;
      height: 23px;
      width: 175px;
    }
  }



  .popupContainer-form-row-fieldsContainer {
    display: inline-block;
    vertical-align: middle;
  }

  .popupContainer-form-subtitle {
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    letter-spacing: 1px;
    font-weight: 100;
    font-size: 21px;
    margin: 10px 5px;
    color: white;
    text-transform: uppercase;
  }

  .popupContainer-form-input {
    border: 0;
    padding: 0 5px;
    height: 23px;
    width: 175px;
    vertical-align: middle;
  }

  .popupContainer-form-input[type="radio"] {
    height: auto;
    width: auto;
    -webkit-appearance: radio;
    -moz-appearance: radio;
    -ms-appearance: radio; /* not currently supported */
    -o-appearance: radio;
  }

  .popupContainer-form-submit {
    background-color: #000;
    padding-top: 6px;
    padding-bottom: 0px;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 18px;
    vertical-align: -2px;
    margin-left: auto;
    width: 100px;
  }

  .popupContainer-form-row.right {
    justify-content: flex-end;
    margin: 15px auto;
  }

  .popupContainer-bannerContainer {
    display: inline-block;
    width: 145px;
    height: 381px;
    vertical-align: top;
  }

  .colonne {
    width: 50%;
    float: left;
    min-width: 200px;
    background-color: #f28d01;
    min-height: 170px;
  }

  .bandeau-logo {
    background-color: #ffffff;
    height: 300px;
  }

  .bandeau-logo .popupContainer-form-row {
    width: 14.5%;
    text-align: center;
    float: left;
  }

  .cheque {
    margin-top: 47px;
  }

  .abp {
    margin-top: 18px;
  }

  .autisme {
    margin-top: 23px;
  }

  .anapedys {
    margin-top: 40px;
  }

  .fnsf {
    margin-top: 38px;
  }

  .ortho {
    margin-top: 41px;
  }

  .bouton-demo-logiciel {
    width: 100%;
    padding: 10px 3%;
    vertical-align: middle;
    background-color: #cccccc;
    display: inline-block;
    text-align: center;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 25px;
    font-weight: 300;
    text-transform: uppercase;
    color: #000;
    cursor: pointer;
    text-decoration: none;
    border-bottom-width: 5px;
  }
  .bouton-demo-logiciel-img {
    border: 0px;
    vertical-align: middle;


  }
  .guide-idel {
    position: relative;
    z-index: 2;
    margin: 0;
    width: 100%;
  }
  .guide-idel img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .anniv {
    display: none;
  }

  .annivContainer {
    display: none;
  }

}

@include media(">=tablet", "<desktop") {

  /*==================================
  =========SECTION NAV================
  ==================================*/


  .headerContainer {
    width: 100%;
    background-color: white;
    text-align: center;
    padding: 10px;
    height: 87px;
    border-bottom: 1px solid #DDDDDD;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .headerContainer-header {
    display: flex;
    align-items: center;
  }

  .headerContainer-header-h1 {
    position: fixed;
    top: -100%;
  }

  .headerContainer-header-link {
    margin: auto;
    display: flex;
    text-decoration: none;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .headerContainer-header-logo {
    display: inline-block;
    padding-right: 10px;
    border-right: 1px #ffffff solid;
    margin-right: 10px;
    //max-height: 23px;
  }

  .headerContainer-header-accroche {
    padding-top: 10px;
    font-family: Arial, sans-serif;
    font-weight: 700;
    font-size: 15px;
    line-height: 1.15;

    span {
      color: $primary-color;
    }
  }

  .headerContainer-header-contactContainer {
    margin-top: 10px;

    display: flex;
    align-items: center;

    a img{
      display: block;
    }
  }

  .headerContainer-header-contact {
    display: inline-block;
    text-align: center;
    color: #ffffff;
    padding: 2px;
    font-size: 14px;
    background-color: grey;
    width: auto;
    margin-right: 5px;
  }

  #page_content_container div#inforeseau img {

    max-width: 100%;
  }


  /*=================================================
  ==================SECTION NAV======================
  ================================================*/

  .navContainer {
    width: 100%;
    padding: 0 3%;
  }

  .navContainer-nav {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }

  .navContainer-liste {
    display: inline-block;
    list-style: none;
    padding: 0;
    font-size: 0;
  }

  .navContainer-liste-element {
    display: inline-block;
    margin-right: 20px;
    font-family: "bignoodletitlingregular";
    font-size: 28px;
    &:last-child{
      margin-right: 0;
    }
  }

  .navContainer-liste-element-lien {
    color: #000000;
    text-decoration: none;
    font-family: Arial, sans-serif;
    font-size: 15px;
    font-weight: 700;
  }


  .navContainer-contact{
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .navContainer-contact-item{
    font-family: Arial, sans-serif;
    font-size: 15px;
    font-weight: 700;
    padding: 10px 15px;
    border: 1px solid #000000;
    border-radius: 5px;
    text-decoration: none;
  }


  .handler_message_container {
    color: white;
    padding: 10px 0px;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
  }

  .handler_message_container.success {

  }

  .handler_message_container.error {

  }

  .handler_message_container h3 {
    margin: 0;
    font-size: 32px;
    font-weight: 300;
  }

  .handler_message_container div {
    font-size: 20px;
  }


  /*=================================================
  ================SECTION FIL D'ACTU=================
  ================================================*/

  .filDactu {
    width: 100%;
    margin-top: 50px;
    padding: 10px 3%;
    padding-top: 10px;
  }

  .filDactu-titreContainer-980 {
    width: 100%;
    position: relative;
  }

  .filDactu-titre {
    position: absolute;
    z-index: -1;
    top: -38px;
    right: 10px;
    font-size: 42px;
    margin: 0;
    color: #e7e7e7;
    font-weight: 100;
  }


  .filDactu-idel {
    background-color: #e7e7e7;
  }

  .filDactu-titreContainer-980 {
    margin: auto;
    padding: 10px 0;
    position: relative;
    width: 100%;
  }

  .filDactu-idel-980 {
    margin: auto;
    position: relative;
    width: 100%;
    padding-bottom: 10px;
  }

  .filDactu-idel-titre {
    color: #273846;
    display: block;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 26px;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
    height: 70px;
    vertical-align: top;
    width: 360px;
    line-height: 50px;
    padding: 0 10px;
  }

  .filDactu-idel-news {
    display: block;
    margin-bottom: 12px;
    margin-left: 10px;
    margin-top: 12px;
    padding-left: 70px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    position: relative;
    width: 340px;
    min-height: 70px;

    background-image: url('../../images/rounded_plus.png');
    background-repeat: no-repeat;
    background-position: 5px center;

    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    -ms-transition: all 400ms;
    -o-transition: all 400ms;
    transition: all 400ms;
  }

  .filDactu-idel-news:hover {
    background-color: rgba(199, 199, 199, 1);
  }

  .filDactu-idel-news-table {
    width: 100%;
  }


  .filDactu-idel-news-border {
    position: absolute;
    width: 1px;
    background-color: #c7c7c7;
    left: 47px;
    top: 0;
    bottom: 0px;
  }

  .filDactu-idel-news-lirePlus {
    text-decoration: none;
    color: #6E6E6E;
  }
  /*
  .filDactu-idel-news-lirePlus:hover {
      background-position: 0 60px;
  }

  .filDactu-idel-news-lirePlus:active {
      background-position: 0 30px;
  }
  */
  .filDactu-idel-news-table td:last-child {
    width: 70px;
  }

  .filDactu-idel-news-title {
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 20px;
    margin: 0;
  }

  .filDactu-idel-news-date {
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 20px;
    margin: 0;
  }

  .filDactu-idel-news-text {
    font-family: Arial, sans-serif;
    font-size: 14px;
    margin: 0;
    text-align: justify;
    max-height: 35px;
  }


  .filDactu-newsletter {
    background-repeat: no-repeat;
    height: 375px;
    padding: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 222px;
    text-align: center;
    z-index: 1;
  }

  .filDactu-newsletter-titre {
    color: #fff;
    display: inline-block;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 24px;
    font-weight: 300;
    margin: 0;
    margin-top: 20px;
    text-align: center;
    text-transform: uppercase;
  }

  .filDactu-newsletter-form {
    text-align: center;
  }

  .filDactu-newsletter-form-input {
    margin: 20px 0;
    text-align: center;
    width: 170px;
  }

  .filDactu-newsletter-form-valider {
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 20px;
    text-decoration: underline;
    text-transform: uppercase;
  }

  .container-filDactu-toutesActus {
    margin-top: 15px;
    width: 50%;
    text-align: center;
  }

  .filDactu-toutesActus {
    color: #000;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;

  }

  .container-filDactu-spacer {
    height: 25px;
  }

  .filDactu-cecilio-container {
    background-color: #e7e7e7;
    padding-top: 10px;
  }

  .filDactu-cecilio {
    width: 100%;
  }

  .filDactu-cecilio-infirmiere {
    width: 35%;
    max-width: 321px;
    margin-top: 80px;
    margin-left: 10px;
    float: left;
  }

  .filDactu-cecilio-titre {
    font-family: "bignoodletitlingregular";
    font-size: 26px;
    color: #273846;
    width: calc(62% - 20px);
    margin-left: 20px;
    display: inline-block;
  }

  .filDactu-cecilio-titre-lien {
    color: #56748e;
  }

  .filDactu-cecilio-news-lirePlus {
    text-decoration: none;
  }

  /*.filDactu-cecilio-news-lirePlus:after {
      content: 'Lire la suite de cette article ...';
      color: #56748e;
      border-bottom: 1px #56748e solid;
  }*/

  .filDactu-cecilio-news {
    display: inline-block;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 15px;
    padding: 5px 5px 5px 70px;
    position: relative;
    width: 60%;
    background-image: url('../../images/rounded_plus.png');
    background-repeat: no-repeat;
    background-position: 5px center;
    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    -ms-transition: all 400ms;
    -o-transition: all 400ms;
    transition: all 400ms;

  }

  .filDactu-cecilio-news:hover {
    background-color: rgba(199, 199, 199, 1);
  }


  .filDactu-cecilio-news-border {
    position: absolute;
    width: 1px;
    background-color: #c7c7c7;
    left: 47px;
    top: 0;
    bottom: 0;
  }

  .filDactu-cecilio-news-lirePlus {
  }

  /*.filDactu-infirLib-news-lirePlus:hover {
      background-position: 0 60px;
  }

  .filDactu-infirLib-news-lirePlus:active {
      background-position: 0 30px;
  }*/

  .filDactu-cecilio-news-titre {
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 20px;
    margin: 0;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .filDactu-cecilio-news-text {
    color: #6E6E6E;
    font-family: Arial, sans-serif;
    font-size: 14px;
    margin: 0;
    text-align: justify;
  }


  /*================================================
  ==SECTION OPTION PARRAINAGE ET TELEINTERVENTION===
  ================================================*/

  .plusDoptions {
    width: 100%;
    padding: 10px 3%;
    margin-top: 30px;
    clear: both;
  }

  .plusDoptions-article {
    display: inline-block;
    width: 49%;
    vertical-align: top;
  }

  .plusDoptions-image {
    display: block;
    width: 72px;
    margin: auto;
  }

  .plusDoptions-titre {
    text-align: center;
    font-family: "bignoodletitlingregular";
    color: #6a6a6a;
  }

  .plusDoptions-text {
    color: #6a6a6a;
    padding: 0 20px 0;
    text-align: justify;
  }


  /*================================================
  =================SECTION CONNEXION================
  ================================================*/

  .connexion {
    width: 100%;
    background-color: grey;
    padding: 10px 3%;
    padding-top: 10px;
    margin-top: 40px;
  }

  .connexion-980 {
    width: 100%;
    position: relative;
  }

  .connexion-titre {
    font-family: "bignoodletitlingregular";
    position: absolute;
    margin: 0;
    color: grey;
    top: -45px;
  }

  .connexion-form-input {
    margin: 4px;
    width: 170px;
  }

  .connexion-form-valider {
    margin: 4px;
    width: 100px;
    background-color: #000000;
    border-color: #000000;
    border-top: 5px #000000 solid;
    border-bottom-width: 1px;
    color: grey;
    font-size: 18px;
    line-height: 17px;
    font-family: "bignoodletitlingregular";
  }


  /*================================================
  ==================SECTION FOOTER==================
  ================================================*/

  .footer {
    width: 100%;
    padding: 10px 3%;
    background-color: #374857;
  }

  .footer-image {
    margin-top: 30px;
    float: left;
  }

  .footer-text {
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px #A4A8AB solid;
    color: #A4A8AB;
    font-size: 14px;
    display: inline-block;
    width: 60%;
  }

  .footer-text-call {
    color: #ffffff;
    display: block;
    font-family: Arial, sans-serif;
    padding: 8px 0;
    padding-left: 30px;
    text-decoration: none
  }


  /*================================================
  ==================SECTION FOOTER==================
  ================================================*/

  .superFooter {
    width: 100%;
    padding: 10px 3% 50px;
    background-color: #000000;
  }

  .superFooter-liste {
    list-style: none;
    padding: 0;
  }

  .superFooter-liste-element-lien {
    text-decoration: none;
    color: #444;
    font-size: 12px;
  }

  .superFooter-liste-element:nth-child(-n+6) .superFooter-liste-element-lien {
    color: #b4b4b4;
  }

  img.pop-parainage {
    width: 100%;
    max-width: 600px;
  }

  .popupContainer-contentContainer h4,
  .popupContainer-contentContainer p {
    padding-left: 10px;
  }


  .popupContainer {
    position: relative;
    background: #FFF;
    width: auto;
    max-width: 600px;
    margin: 20px auto;
    font-family: Arial, sans-serif;
    font-size: 12px;
  }

  .popupContainer-title {
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    text-transform: uppercase;
    margin: 0;
    padding: 7px 10px 0;
    color: white;
    letter-spacing: 1px;
    font-weight: 100;
    font-size: 30px;
  }

  .popupContainer-subtitle {
    font-size: 17px;
    margin: 15px 0;
  }

  .popupContainer-subtitle.error {
    border: 2px dashed #ff243a;
    padding: 15px;
    color: #ff243a;
  }

  .popupContainer-subtitle.success {
    border: 1px dashed #27a230;
    padding: 15px;
    color: #27a230;
  }

  .popupContainer-subtitle:last-child {
    margin-bottom: 5px;
  }

  .popupContainer-contentContainer {
    color: #4F4F4F;
  }

  .popupContainer-contentContainer p {
    margin: 0 0 10px;
  }

  .popupContainer-form {
    padding: 7px 10px;
    position: relative;
  }

  #popupParrainage .popupContainer-form {
    display: inline-block;
    height: 430px;
    margin-top: -3px;
  }

  .popupContainer-form-row {
    display: flex;
    align-items: center;
    margin: 5px auto;
    flex-wrap: wrap;
    max-width: 320px;

    .label-container {
      font-weight: bold;
      width: 100px;
    }

    input[type="text"], input[type="email"]{
      margin-left: auto;
      border: 0;
      padding: 0 5px;
      height: 23px;
      width: 175px;
    }
  }

  .popupContainer-form-row-fieldsContainer {
    display: inline-block;
    vertical-align: middle;
  }

  .popupContainer-form-subtitle {
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    letter-spacing: 1px;
    font-weight: 100;
    font-size: 21px;
    margin: 10px 5px;
    color: white;
    text-transform: uppercase;
  }

  .popupContainer-form-input {
    border: 0;
    padding: 0 5px;
    height: 23px;
    width: 180px;
    vertical-align: middle;
  }

  .popupContainer-form-input[type="radio"] {
    height: auto;
    width: auto;
    -webkit-appearance: radio;
    -moz-appearance: radio;
    -ms-appearance: radio; /* not currently supported */
    -o-appearance: radio;
  }

  .popupContainer-form-submit {
    background-color: #000;
    padding-top: 6px;
    padding-bottom: 0px;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 18px;
    vertical-align: -2px;
    margin-left: 10px;
    width: 100px;
  }

  .popupContainer-form-row.right {
    justify-content: flex-end;
    margin: 15px auto;
  }

  .popupContainer-bannerContainer {
    display: inline-block;
    width: 145px;
    height: 381px;
    vertical-align: top;
  }

  .colonne {
    width: 50%;
    float: left;
    min-width: 200px;
    background-color: #f28d01;
    min-height: 220px;
  }

  .bandeau-logo {
    background-color: #ffffff;
    height: 350px;
  }

  .bandeau-logo .popupContainer-form-row {
    width: 14.5%;
    text-align: center;
    float: left;
  }

  .cheque {
    margin-top: 47px;
  }

  .abp {
    margin-top: 18px;
  }

  .autisme {
    margin-top: 23px;
  }

  .anapedys {
    margin-top: 40px;
  }

  .fnsf {
    margin-top: 38px;
  }

  .ortho {
    margin-top: 41px;
  }


  /*================================================
  ============SECTION DEMANDE D'INFOS===============
  ================================================*/

  .plusDinfos {
    width: 100%;
    padding: 10px;
    position: fixed;
    z-index: 10;
    bottom: 0;
    background-color: grey;
    text-align: center;

    &.item-active{
      position: absolute;
      bottom: auto;

      .plusDinfos-close {
        display: block;
      }
    }
  }

  .plusDinfos-titre {
    margin: 0;
    font-family: "bignoodletitlingregular";
    color: #ffffff;
    cursor: pointer;
  }

  .plusDinfos-form {
    max-height: 0;
    overflow: hidden;
    width: 70%;
    margin: 0 15%;

    transition: max-height 200ms ease-in-out;

    &.is-opened{
      max-height: 250px;
    }
  }

  .footer-text-call .plusDinfos-form-input {
    width: auto;
  }

  .plusDinfos-form-input {
    width: 45%;
  }

  .plusDinfos-form-input,
  .plusDinfos-form-valider {
    margin: 5px 4px;
  }

  .plusDinfos-form-valider {
    background-color: #000;
    border: 5px #000 solid;
    border-bottom: 1px #000 solid;
    border-top: 4px #000 solid;
    color: #fff;
    cursor: pointer;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
    width: 150px;
  }

  .plusDinfos-close {
    display: none;
  }


  .bouton-demo-logiciel {
    width: 100%;
    vertical-align: top;
    background-color: #cccccc;
    display: inline-block;
    text-align: center;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    border-top-width: 5px;
    color: #000000;
    font-size: 25px;
  }
  .bouton-demo-logiciel-img {
    border: 0px;
    vertical-align: middle;

  }
  .guide-idel {
    position: relative;
    z-index: 2;
    /* Centrer horizontalement */
    left: 50%;
    margin-left: -1000px;
    width: 2000px;
  }

  .guide-idel img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

}

@include media("<tablet") {
  /*==================================
=========SECTION HEADER================
==================================*/


  .headerContainer {
    width: 100%;
    background-color: white;
    text-align: center;
    padding: 10px;
  }

  .headerContainer-header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .headerContainer-header-h1 {
    position: fixed;
    top: -100%;

  }

  .headerContainer-header-link {
    width: 100%;
    margin: auto;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .headerContainer-header-logo {
    display: block;
    margin: auto;
    //max-height: 23px;
  }

  .headerContainer-header-accroche {
    font-family: Arial, sans-serif;
    font-weight: 700;
    font-size: 15px;
    line-height: 1.15;
    max-width: 300px;

    span {
      color: $primary-color;
    }
  }

  .headerContainer-header-contactContainer {
    margin-top: 10px;

    display: flex;
    align-items: center;

    a img{
      display: block;
    }
  }

  .headerContainer-header-contact {
    display: inline-block;
    text-align: center;
    color: #ffffff;
    padding: 2px;
    font-size: 14px;
    background-color: gray;
    margin-right: 5px;
  }

  #page_content_container div#inforeseau img {

    max-width: 100%;
  }

  .reseau {
    margin-top: -45px;
  }


  /*=================================================
  ==================SECTION NAV======================
  ================================================*/

  .navContainer {
    width: 100%;
    padding: 0 0 10px 0;
  }

  .navContainer-nav {
    text-align: center;
  }

  .navContainer-liste {
    display: inline-block;
    list-style: none;
    padding: 0;
    font-size: 0;
    border-bottom: 1px #e4e4e4 solid;
  }

  .navContainer-liste-element {
    display: inline-block;
    margin-right: 15px;
    font-family: "bignoodletitlingregular";
    font-size: 19px;
    &:last-child{
      margin-right: 0;
    }
  }

  .navContainer-liste-element-lien {
    color: #000000;
    text-decoration: none;
    font-family: Arial, sans-serif;
    font-size: 15px;
    font-weight: 700;
  }


  .navContainer-contact{
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    margin-top: 10px;
  }

  .navContainer-contact-item{
    font-family: Arial, sans-serif;
    font-size: 15px;
    font-weight: 700;
    padding: 10px 15px;
    border: 1px solid #000000;
    border-radius: 5px;
    text-decoration: none;
  }


  .handler_message_container {
    color: white;
    padding: 10px 0px;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
  }

  .handler_message_container.success {

  }

  .handler_message_container.error {

  }

  .handler_message_container h3 {
    margin: 0;
    font-size: 32px;
    font-weight: 300;
  }

  .handler_message_container div {
    font-size: 20px;
  }


  /*=================================================
  ================SECTION FIL D'ACTU=================
  ================================================*/

  .filDactu {
    width: 100%;
    margin-top: 50px;
    padding: 10px 3%;
    padding-top: 10px;
  }

  .filDactu-titreContainer-980 {
    width: 100%;
    padding: 10px;
    position: relative;
  }

  .filDactu-titre {
    position: absolute;
    z-index: -1;
    top: -38px;
    right: 10px;
    font-size: 42px;
    margin: 0;
    color: #e7e7e7;
    font-weight: 100;
  }


  .filDactu-idel {
    background-color: #e7e7e7;
  }

  .filDactu-titreContainer-980 {
    margin: auto;
    padding: 10px 0;
    position: relative;
    width: 100%;
  }

  .filDactu-idel-980 {
    margin: auto;
    position: relative;
    width: 100%;
  }

  .filDactu-idel-titre {
    color: #273846;
    display: block;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 26px;
    font-weight: 400;
    margin: 0;
    padding: 0 10px;
    text-transform: uppercase;
    height: 70px;
    vertical-align: top;
    line-height: 50px;
  }

  .filDactu-idel-news {
    display: block;
    margin-bottom: 10px;
    padding: 10px;
    position: relative;
    width: 100%;
    min-height: 70px;
  }

  .filDactu-idel-news-border {
    display: none;
  }

  .filDactu-idel-news-lirePlus {
    text-decoration: none;
    color: #6E6E6E;
  }
  /*
  .filDactu-idel-news-lirePlus:hover {
      background-position: 0 60px;
  }

  .filDactu-idel-news-lirePlus:active {
      background-position: 0 30px;
  }
  */

  .filDactu-idel-news-table {
    width: 100%;
  }


  .filDactu-idel-news-table td:last-child {
    width: 70px;
  }

  .filDactu-idel-news-title {
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 20px;
    margin: 0;
    text-decoration: underline;
  }

  .filDactu-idel-news-date {
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 20px;
    margin: 0;
  }

  .filDactu-idel-news-text {
    font-family: Arial, sans-serif;
    font-size: 14px;
    margin: 0;
    text-align: justify;
    max-height: 35px;
  }


  .container-filDactu-toutesActus {
    margin-top: 15px;
    width: 100%;
    text-align: center;
  }

  .filDactu-toutesActus {
    color: #000;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
  }


  .filDactu-newsletter {
    background-repeat: no-repeat;
    padding: 20px;
    width: 222px;
    margin: 30px auto 0;
    text-align: center;
  }

  .filDactu-newsletter-titre {
    color: #fff;
    display: inline-block;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 24px;
    font-weight: 300;
    margin: 0;
    margin-top: 20px;
    text-align: center;
    text-transform: uppercase;
  }

  .filDactu-newsletter-form {
    text-align: center;
  }

  .filDactu-newsletter-form-input {
    margin: 20px 0;
    text-align: center;
    width: 170px;
  }

  .filDactu-newsletter-form-valider {
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 20px;
    text-decoration: underline;
    text-transform: uppercase;
  }


  .container-filDactu-spacer {
    height: 25px;
  }


  .filDactu-cecilio-container {
    background-color: #e7e7e7;
    padding-top: 10px;
  }

  .filDactu-cecilio {
    width: 100%;
  }

  .filDactu-cecilio-infirmiere {
    display: none;
  }

  .filDactu-cecilio-titre {
    font-family: "bignoodletitlingregular";
    font-size: 26px;
    color: #273846;
    width: 100%;
    padding: 0 10px;
    display: inline-block;
  }

  .filDactu-cecilio-titre-lien {
    color: #56748e;
  }

  .filDactu-cecilio-news-lirePlus {
    text-decoration: none;
  }

  /*.filDactu-cecilio-news-lirePlus:after {
      content: 'Lire la suite de cette article ...';
      color: #56748e;
      border-bottom: 1px #56748e solid;
  }*/

  .filDactu-cecilio-news {
    display: inline-block;
    margin-bottom: 10px;
    padding: 10px;
    position: relative;
    width: 100%;
  }

  .filDactu-cecilio-news-border {
    display: none;
  }

  .filDactu-cecilio-news-lirePlus {
  }

  /*.filDactu-infirLib-news-lirePlus:hover {
      background-position: 0 60px;
  }

  .filDactu-infirLib-news-lirePlus:active {
      background-position: 0 30px;
  }*/

  .filDactu-cecilio-news-titre {
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 20px;
    margin: 0 0 10px 0;
    text-transform: uppercase;
    text-decoration: underline;
  }

  .filDactu-cecilio-news-text {
    color: #6E6E6E;
    font-family: Arial, sans-serif;
    font-size: 14px;
    margin: 0;
    text-align: justify;
  }


  /*================================================
  ==SECTION OPTION PARRAINAGE ET TELEINTERVENTION===
  ================================================*/

  .plusDoptions {
    width: 100%;
    padding: 10px 3%;
    margin-top: 30px;
  }

  .plusDoptions-article {
    display: inline-block;
    width: 49%;
  }

  .plusDoptions-image {
    display: block;
    width: 72px;
    margin: auto;
  }

  .plusDoptions-titre {
    text-align: center;
    font-family: "bignoodletitlingregular";
    color: #6a6a6a;
  }

  .plusDoptions-text {
    display: none;
  }


  /*================================================
  =================SECTION CONNEXION================
  ================================================*/

  .connexion {
    width: 100%;
    background-color: gray;
    padding: 10px 3%;
    padding-top: 10px;
    margin-top: 40px;
  }

  .connexion-980 {
    width: 100%;
    position: relative;
  }

  .connexion-titre {
    font-family: "bignoodletitlingregular";
    position: absolute;
    margin: 0;
    color: gray;
    top: -45px;
    left: calc(50% - 84px);
  }

  .connexion-form-input {
    margin: 4px;
    width: calc(37% - 10px);
  }

  .connexion-form-valider {
    margin: 4px;
    width: calc(25% - 10px);
    background-color: #000000;
    border-color: #000000;
    border-top: 5px #000000 solid;
    border-bottom-width: 1px;
    color: gray;
    font-size: 18px;
    line-height: 17px;
    font-family: "bignoodletitlingregular";
  }


  /*================================================
  ==================SECTION FOOTER==================
  ================================================*/

  .footer {
    width: 100%;
    padding: 10px 3%;
    background-color: #374857;
  }

  .footer-text {
    padding-left: 20px;
    border-left: 1px #A4A8AB solid;
    color: #A4A8AB;
    font-size: 14px;
  }


  /*================================================
  ==================SECTION FOOTER==================
  ================================================*/

  .superFooter {
    width: 100%;
    padding: 10px 3% 50px;
    background-color: #000000;
  }

  .superFooter-liste {
    list-style: none;
    padding: 0;
  }

  .superFooter-liste-element-lien {
    text-decoration: none;
    color: #444;
    font-size: 12px;
  }

  .superFooter-liste-element:nth-child(-n+6) .superFooter-liste-element-lien {
    color: #b4b4b4;
  }

  img.pop-parainage {
    width: 100%;
    max-width: 600px;
  }

  .popupContainer-contentContainer h4,
  .popupContainer-contentContainer p {
    padding-left: 10px;
  }


  .popupContainer {
    position: relative;
    background: #FFF;
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    font-family: Arial, sans-serif;
    font-size: 12px;
  }

  .popupContainer-title {
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    text-transform: uppercase;
    margin: 0;
    padding: 7px 10px 0;
    color: white;
    letter-spacing: 1px;
    font-weight: 100;
    font-size: 30px;
  }

  .popupContainer-subtitle {
    font-size: 17px;
    margin: 15px 0;
  }

  .popupContainer-subtitle.error {
    border: 2px dashed #ff243a;
    padding: 15px;
    color: #ff243a;
  }

  .popupContainer-subtitle.success {
    border: 1px dashed #27a230;
    padding: 15px;
    color: #27a230;
  }

  .popupContainer-subtitle:last-child {
    margin-bottom: 5px;
  }

  .popupContainer-contentContainer {
    color: #4F4F4F;
  }

  .popupContainer-contentContainer p {
    margin: 0 0 10px;
  }

  .popupContainer-form {
    padding: 7px 10px;
    position: relative;
  }

  #popupParrainage .popupContainer-form {
    display: inline-block;
    width: 100%;
    min-height: 381px;
    text-align: center;
  }

  .popupContainer-form-row {
    display: flex;
    align-items: center;
    margin: 5px auto;
    flex-wrap: wrap;
    max-width: 320px;

    .label-container {
      font-weight: bold;
      width: 100px;
    }

    input[type="text"], input[type="email"]{
      margin-left: auto;
      border: 0;
      padding: 0 5px;
      height: 23px;
      width: 175px;
    }
  }

  .popupContainer-form-row-fieldsContainer {
    display: inline-block;
    vertical-align: middle;
  }

  .popupContainer-form-subtitle {
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    letter-spacing: 1px;
    font-weight: 100;
    font-size: 21px;
    margin: 10px 5px;
    color: white;
    text-transform: uppercase;
  }

  .popupContainer-form-input {
    border: 0;
    padding: 0 5px;
    height: 23px;
    width: 180px;
    vertical-align: middle;
  }

  .popupContainer-form-input[type="radio"] {
    height: auto;
    width: auto;
    -webkit-appearance: radio;
    -moz-appearance: radio;
    -ms-appearance: radio; /* not currently supported */
    -o-appearance: radio;
  }

  .popupContainer-form-submit {
    background-color: #000;
    padding-top: 6px;
    padding-bottom: 0px;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 18px;
    vertical-align: -2px;
    margin-left: 10px;
    width: 100px;
  }

  .popupContainer-form-row.right {
    justify-content: flex-end;
    margin: 15px auto;
  }

  .popupContainer-bannerContainer {
    /*display: inline-block;
    width: 145px;
    height: 381px;
    vertical-align: top;*/
    display: none;
  }

  .bandeau-logo .popupContainer-form-row {
    text-align: center;
    width: 175px;
    padding: 15px;
    margin: auto;
  }

  .bandeau-logo {
    background-color: #ffffff;
    width: 75%;
    min-width: 180px;
    padding: 10px;
    margin: 20px auto;
  }
  .popupContainer-form-row img {
    margin: 0 18px;
  }


  /*================================================
  ============SECTION DEMANDE D'INFOS===============
  ================================================*/

  .plusDinfos {
    width: 100%;
    padding: 10px;
    position: fixed;
    z-index: 10;
    bottom: 0;
    background-color: gray;
    text-align: center;

    &.item-active{
      position: absolute;
      bottom: auto;

      .plusDinfos-close {
        display: block;
      }
    }
  }

  .plusDinfos-titre {
    margin: 0;
    font-family: "bignoodletitlingregular";
    color: #ffffff;
    cursor: pointer;
  }

  .plusDinfos-form {
    overflow: hidden;
    width: 70%;
    margin: 0 15%;

    max-height: 0;

    transition: max-height 200ms ease-in-out;

    &.is-opened{
      max-height: 200px;
    }
  }

  .plusDinfos-form-input {
    width: 100%;
  }
  .footer-text-call .plusDinfos-form-input {
    width: auto;
  }
  .footer-text-call {
    color: #ffffff;
    display: block;
    font-size: 14px;
  }

  .plusDinfos-form-input,
  .plusDinfos-form-valider {
    margin: 4px 0;
  }

  .plusDinfos-form-valider {
    background-color: #000;
    border: 5px #000 solid;
    border-bottom: 1px #000 solid;
    border-top: 4px #000 solid;
    color: #fff;
    cursor: pointer;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
    width: 150px;
  }

  .plusDinfos-close {
    display: none;
  }

  .bouton-demo-logiciel {
    width: 100%;
    padding: 10px 3%;
    border-top: 10px;
    height: 90px;
    vertical-align: bottom;
    background-color: #cccccc;
    display: inline-block;
    text-align: center;
    font-family: "bignoodletitlingregular", Arial, sans-serif;
    border-top-width: 5px;
    color: #000000;
    font-size: 25px;
    line-height: 25px;
  }
  .bouton-demo-logiciel-img {
    border: 0px;
    vertical-align: middle;
  }


  .guide-idel {
    display: none;
  }


  .anniv {
    position: relative;
    z-index: 2;
    /* Centrer horizontalement */
    left: 50%;
    margin-left: -150px;
    width: 300px;
  }
}

.plusDinfos-980 a {
  background-color: #000;
  border-bottom: none;
  color: #fff;
  cursor: pointer;
  font-family: "bignoodletitlingregular", Arial, sans-serif;
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
  padding: 6px 5px 2px;
  text-decoration: none;
  display: inline-block;
  line-height: 1.2;

  @include media(">=desktop") {
    margin-left: 20px;
  }
}
