.plusDinfos {
  width: 100%;
  z-index: 2;
  position: fixed;
  bottom: 0;
}

.seoContainer {
  margin: auto;
  max-width: 980px;
  width: 100%;
}

.seoContainer img{
  width: auto;
  max-width: 980px;
  height: auto;
}

.seoContainer-section-headContainer{

}

.seoContainer-section-head-item{
  display: inline-block;
  width: 50%;
  vertical-align: top;
  padding: 10px;

  iframe{
    width: 100%;
  }
}

.seoContainer-head-titre {
  font-family: Arial, sans-serif;
  font-weight: 100;
  color: #818181;
  font-size: 55px;
  line-height: 1;
  margin: 0;
}

.seoContainer-head-img {

}


.seoContainer-section-head-description{
  margin-top: 20px;
}

.seoContainer-section-head-description strong{
  color: black;
}

.seoContainer-section-head-description strong{
  color: black;
}

.seoContainer-section-head-description-title{
  margin: 10px 0px 0px;
  color:#000000;
}
.seoContainer-section-head-description-content{
  color: #8B8B8B;
  font-size: 14px;
}

.seoContainer-section-head-description-content a{
  text-decoration: underline;
}

.seoContainer-verreContainer {
  padding: 20px;
  display: inline-block;
  float: left;
}

.seoContainer-article-titre {
  font-size: 16px;
  margin: 0;
  margin-top: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  border-left: 1px #8b8b8b solid;
}

.seoContainer-article-text {
  margin: 0;
  padding-bottom: 10px;
  padding-left: 20px;
  border-left: 1px #8b8b8b solid;
  color: #8B8B8B;
  font-size: 16px;
  font-weight: bold;
}

.seoContainer-article-liste {
  margin-top: 0;
  font-size: 14px;
  color: #818181;
}

.seoContainer-article-top {
  display: inline-block;
  max-width: 780px;
  width: 100%;
  vertical-align: top;
  margin-bottom: 10px;
}

.seoContainer-article-colGauche {
  clear: both;
  max-width: 490px;
  width: 100%;
  display: inline-block;
}

.seoContainer-article-colDroite {
  max-width: 490px;
  width: 100%;
  display: inline-block;
}

/* page SAAS*/
.image-saas{
  margin-top: 20px;
}

.seoContainer-section-head-item-saas{
  display: inline-block;
  float:left;
  width: 50%;
  vertical-align: top;
  padding-left: 10px;
  padding-right: 10px;
}

.paragraphe1 .seoContainer-section-head-description-content,
.paragraphe2 .seoContainer-section-head-description-content {
  color: #000000;
  line-height: 1.4;
}

/* page bdk*/
.seoContainer-article-titre-bdk {
  font-size: 13px;
  margin: 0;
  margin-top: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  border-left: 1px #8b8b8b solid;
}

.obligation-legale{
  float: left;
  margin-right: 20px;
}

.texte-obligation-legale{
  width: 80%;
}

/* page app mobile connectee*/

.main{
  float: left;
}


.seoContainer .main img {
  width: auto;
  max-width: 980px;
  height: auto;
  max-height: 300px;
  margin-right: 20px;
}

/* page whatsmoov*/

.btn-activez {
  border-radius: 16px;
  width: 220px;
  text-align: center;
  color: #ffffff;
  background-color: #000000;
  float: right;
  margin-right: 11%;
  margin-top: -400px;
}

.btn-activez:hover {
  background-color: #494948;
}

.btn-activez-flash {
  border-radius: 16px;
  width: 220px;
  text-align: center;
  color: #ffffff;
  background-color: #000000;
  float: right;
  margin-right: 9%;
  margin-top: -630px
}

.btn-activez-flash:hover {
  background-color: #494948;
}

a.flashbilan {
  color: #000000;
  text-decoration: none;
}

.google-play {
  float: right;
  margin-top: -500px;
  margin-right: 100px;
}

.seoContainer-section-head-item-flash{
  display: inline-block;
  width: 100%;
  float:left;
}

.pendule {
  float: right;
  margin-top: -880px;
  margin-right: 71px;
}

.paragraphe2 {
  width: 50%;
  float: right;
  margin-top: 519px;
  padding-left: 10px;
}

.paragraphe2 strong {
  color:#000000;
}




.paragraphe1 {
  padding-right: 10px;
}



@include media('>=tablet','<desktop'){
  .seoContainer {
    padding: 0 3%;

    img{
      width: auto;
      max-width: 640px;
      height: auto !important;
    }
  }

  .seoContainer-section-head-item-saas{
    display: inline-block;
    width: 100%;
  }
}


@include media('<tablet'){
  .seoContainer-section-head-item{
    width: 100%;
  }

  .seoContainer-section-head-item-saas{
    display: inline-block;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .seoContainer {
    padding: 0 3%;

    img{
      width: auto;
      max-width: 320px;
      height: auto !important;
    }
  }

  .seoContainer-head-titre{
    font-size: 30px;
  }

  .obligation-legale{
    margin-right: 20px;
  }

  .mentions{
    font-size: 8pt;
  }

  .btn-activez {
    float: none;
  }
}


