@import 'base/variables';

@import 'vendors/include-media';
@import 'vendors/normalize';
@import 'vendors/boltform';
@import 'vendors/tartemeringuee';
@import '../js/vendors/magnific-popup/magnific-popup.css';
@import '../js/vendors/owl/owl-carousel/owl.carousel.css';
@import '../js/vendors/owl/owl-carousel/owl.theme.css';
//@import '../js/vendors/owl/owl-carousel/owl.transitions.css';

@import 'base/animations';
@import 'base/fonts';
@import 'base/forms';
@import 'base/functions';
@import 'base/helpers';
@import 'base/mixins';
@import 'base/typography';

@import 'layout/generic-common';
@import 'layout/common';

@import 'partials/feedbacks';
@import 'partials/orthoptistes';

@import "pages/simple";

[data-route="homepage"]{
	@import 'pages/generic-home';
	@import 'pages/home';
}

[data-route="actuNews"], [data-route="actuNewsId"]{
	@import 'pages/actu';
}

[data-route="allNews"]{
	@import 'pages/list-actu';
}

[data-route="record"][data-slug="logiciel"]{
	@import 'pages/generic-logiciel';
	@import 'pages/logiciel';
}

[data-route="record"][data-slug="services"]{
	@import 'pages/generic-services';
	@import 'pages/services';
}

[data-route="record"][data-slug="offres"]{
	@import 'pages/offres';
}

[data-route="record"][data-slug="offres-detail"]{
	@import 'pages/generic-offres-detail';
	@import 'pages/offres-detail';
}

[data-route="record"][data-slug="inscription"]{
	@import 'pages/inscription';
}

[data-record-group="software-feature"]{
	@import 'pages/generic-seo';
}

[data-record-group="custom-offer"]{
	@import 'pages/offres-custom';
}

[data-type='landings']{
    @import 'pages/landings';
}

