.plusDinfos {
  width: 100%;
  z-index: 2;
  position: fixed;
  bottom: 0;
}

.actuContainer {
  margin: auto;
  max-width: 980px;
  width: 90%;
}

.actuContainer-section-headContainer{

}

.actuContainer-section-head-item{
  /*display: inline-block;
  width: 50%;
  vertical-align: top;*/
  padding: 10px;
}

.actuContainer-head-titre {
  font-family: Arial, sans-serif;
  font-weight: 100;
  color: #818181;
  font-size: 42px;
  line-height: 1;
  margin: 0;
}

.actuContainer-section-head-img-container {
  float: right;
  display: block;
  padding: 40px;
}


.actuContainer-section-head-description{
  margin-top: 20px;
}

.actuContainer-section-head-description strong{
  color: black;
}

.actuContainer-section-head-description-title{
  margin: 10px 0px 0px;
}
.actuContainer-section-head-description-content{
  color: #8B8B8B;
  font-size: 14px;
}

.actuContainer-section-head-description-content a{
  text-decoration: underline;
}

.actuContainer-verreContainer {
  padding: 20px;
  display: inline-block;
  float: left;
}

.actuContainer-article-titre {
  font-size: 16px;
  margin: 0;
  margin-top: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  border-left: 1px #8b8b8b solid;
}

.actuContainer-article-text {
  margin: 0;
  padding-bottom: 10px;
  padding-left: 20px;
  border-left: 1px #8b8b8b solid;
  color: #8B8B8B;
  font-size: 16px;
  font-weight: bold;
}

.actuContainer-article-liste {
  margin-top: 0;
  font-size: 14px;
  color: #818181;
}

.actuContainer-article-top {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin-bottom: 10px;
}

.actuContainer-article-colGauche {
  clear: both;
  width: 45%;
  display: inline-block;
}

.actuContainer-article-colDroite {
  width: 45%;
  display: inline-block;
}
