.orthoptistes{
    padding: 10px 0;
    background: #F1F1F4;
    margin-top: 100px;
    @include media('<tablet'){
        padding: 50px 0;
        margin: 50px 3%;
    }
    &-container{
        display: flex;
        justify-content: center;
        gap: 65px;
        flex-wrap: wrap;
        align-items: center;
        @include media('<tablet'){
            gap: 20px;
        }
    }
    &-left, &-right{
        max-width: 100%;
        @include media('<tablet'){
            padding: 0 10px;
        }
        svg{
            max-width: 100%;
            height: auto;
        }
    }
}
