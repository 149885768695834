
.feedbacks-section{
  background-image: linear-gradient(to bottom, #f7f7f9, #ededf0);
  padding-bottom: 110px;


  &_title {
    font-family: Arial, sans-serif;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 1.5px;
    text-align: center;
    text-transform: uppercase;
    color: #3f3f3f;
    padding-top: 50px;
    padding-bottom: 30px;

    @include media('<tablet'){
      width: 212px;
      margin: auto;
      font-size: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
    }

  }

  .feedback{
    display: flex;
    flex-direction: column;
    max-width: 880px;
    width: 100%;
    height: 260px;
    margin: auto;
    border-radius: 7px;
    box-shadow: 0 15px 30px -15px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 30px 90px;

    @include media('<desktop'){
      width: 85%;
    }

    @include media('<tablet'){
      max-width: 280px;
      padding: 22px;
      height: 420px;
    }

    &-slide{
      height: 100%;
      /*display: flex;
      align-items: center;
      justify-content: center;*/
    }

    &_body{
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      height: 100px;

      @include media('<tablet'){
        height: 280px;
      }

      &_text{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        font-size: 15px;
        font-weight: bold;
        line-height: 1.56;
        letter-spacing: 0.18px;
        color: #000000;

        @include media('<tablet'){
          -webkit-line-clamp: 12;
        }

        p{
          margin: 0;
        }
      }

      &_quote{
        position: absolute;
        &--opened{
          left: -60px;
          top: 0;
          @include media('<tablet'){
            left: -10px;
            top: -10px;
          }

        }
        &--closed{
          right: -55px;
          bottom: 0;
          transform: rotate(-180deg);
          @include media('<tablet'){
            right: -10px;
            bottom: -10px;
          }
        }
      }

      strong{
        color: $primary-color;
      }
    }

    &_meta{
      margin: auto auto 10px;
      text-align: center;
      font-size: 13px;
      font-weight: bold;
      font-style: italic;

      &_username{
        margin-right: 10px;
        margin-left: 4px;
      }


      &_date{
        margin-left: 4px;
      }
    }

    &_footer{
      margin: 0 auto;
      text-align: center;
      font-size: 13px;
      font-style: italic;
      color: #949494;

      p{
        margin: 0;
      }
    }
  }

  .feedbacks-slider{

    @include media('<tablet'){
      margin-bottom: 60px;
    }

    &_controls{
      max-width: 880px;
      width: 100%;
      margin: auto;
      position: relative;

      @include media('<desktop'){
        width: 85%;
      }

      @include media('<tablet'){
        max-width: 280px;
      }


    }

    &_control{
      position: absolute;
      top: -148px;
      fill: $primary-color;
      cursor: pointer;
      user-select: none;
      outline: none;
      width: 20px;

      @include media('<tablet'){
        top: -280px;
      }

      &--prev{
        left: -40px;
      }
      &--next{
        transform: rotate(-180deg);
        right: -40px;
      }
    }

    &_pagination{
      padding-top: 10px;
      text-align: center;
    }
  }
}
