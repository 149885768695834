button {
  touch-action: manipulation;
}

.consent-ui--banner{
  position: fixed;
  z-index: 99999;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 3rem 1rem 1rem;
  color: #333;
  background-color: #f7f7f9;
}

.consent-banner-sticky{
  bottom: 77px;
}

.consent-ui--banner-text {
  margin-bottom: 1rem;
  text-align: left;
  line-height: normal;
  color: black;
  font-size: 18px;
}

.consent-ui--banner-actions {
  text-align: center;
}

.consent-ui--banner-actions .accept, .consent-ui--banner-actions .deny,  .consent-ui--banner-actions .details{
  margin: 1rem;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  border-radius: 12px;
  line-height: 1.2;
  cursor: pointer;
  transition: background-color,color,border-color .25s ease-in-out;
  outline: none;
  color: white;
  text-decoration: none;
  padding: 12px 19px;
  min-width: 170px;
  display: inline-block;
  background-color: $primary-color;
  border: 1px solid $primary-color;
}

.consent-ui--banner-actions .accept:hover, .consent-ui--banner-actions .deny:hover,  .consent-ui--banner-actions .details:hover{
  background-color: #f7f7f9;
  color: $primary-color;
}

.consent-ui--close {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 0;
  font-size: 2em;
  color: #333;
  border: none;
  width: 50px;
  margin: 0;
  cursor: pointer;

  @include media('<tablet'){
   top:0;
    opacity:0.5;
  }
}

.consent-ui--close:hover {
background-color: $primary-color;
}


// Personaliser pop up

.consent-ui--details {
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  padding: 1rem;
}

.consent-ui--details-modal {
  position: relative;
  width: 70%;
  margin: auto;
  max-height: calc(100vh - 2rem);
  padding: 3rem;
  color: #333;
  background-color: white;
  box-shadow: 0 0 2rem rgba(0, 0, 0, .5);
  overflow: auto;

  @include media('<tablet'){
    width: 100%;
    padding: 1rem;
    overflow-y: scroll;
    scrollbar-width: thin;
  }
}

.consent-ui--item-choices {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  @include media('<tablet') {
    flex-flow: column;
    align-items: center;
  }
}

.consent-ui--item-accept button, .consent-ui--item-deny button {
  color: white;
  margin: 1rem;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  border-radius: 12px;
  line-height: 1.2;
  cursor: pointer;
  transition: background-color,color,border-color .25s ease-in-out;
  outline: none;
  text-decoration: none;
  padding: 12px 19px;
  min-width: 170px;
  display: inline-block;

  @include media('<tablet'){
    width: 80%;
    padding: 1em;
  }
}

.consent-ui--item-accept button:hover, .consent-ui--item-deny button:hover {
  background-color: white;
  color: black;
}

.consent-ui--item-accept button{
  background-color: #0a0;
  border: 1px solid #0a0;

  .consent-ui--item[data-status=false] &{
    opacity: 0.5;
  }
}

.consent-ui--item-deny button{
  background-color: #aa0000;
  border: 1px solid #aa0000;

  .consent-ui--item[data-status=true] &{
    opacity: 0.5;
  }
}

.consent-ui--item-label, .consent-ui--item-choices{
  background-color: #f7f7f9;
}

.consent-ui--item-label{
  padding-left:1em;
  padding-top: 1em;
}

.consent-ui--item-choices{
  margin-bottom: 1em;
}

.consent-ui--item-policy{
  margin: 0;
}
